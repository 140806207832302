import { Action, ActionReducer, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ThesisSupervisorState } from './thesis.states';
import { ThesisActions } from './thesis.actions';
import { ThesisSupervisorResponse } from '@sharedModels/thesis-supervisor-response';

const initialState: ThesisSupervisorState = {
  currentThesisSupervisor: {} as ThesisSupervisorResponse, //TODO ThesisSupervisorResponse | undefined not empty object
};

const _thesisSupervisorReducer: ActionReducer<ThesisSupervisorState, Action> = createReducer(
  // Supply the initial state
  initialState,
  on(ThesisActions.setThesisSupervisor, (state: ThesisSupervisorState, thesis: ThesisSupervisorResponse) => ({
    ...state,
    currentThesisSupervisor: { ...thesis },
  })),
  on(ThesisActions.getThesisSupervisor, (state: ThesisSupervisorState) => ({
    ...state,
    currentThesisSupervisor: {} as ThesisSupervisorResponse,
  }))
);

export function thesisSupervisorReducer(state: ThesisSupervisorState, action: Action) {
  return _thesisSupervisorReducer(state, action);
}

// Selectors
export const getThesisSupervisorState = createFeatureSelector<ThesisSupervisorState>('thesisSupervisorState');
export const getThesisSupervisor = createSelector(getThesisSupervisorState, (state: ThesisSupervisorState) => state.currentThesisSupervisor);
