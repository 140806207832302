/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentResponse } from '../models/document-response';
import { GetDocumentRequest } from '../models/get-document-request';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createDocumentAsync
   */
  static readonly CreateDocumentAsyncPath = '/Documents';

  /**
   * Create document attached to thesis.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDocumentAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDocumentAsync$Plain$Response(params?: {
    body?: {
'ThesisId': string;
'DocumentTypeCode': string;
'SpecialisationName': string;
'ProjectCode': string;
'FileToUpload': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.CreateDocumentAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentResponse>;
      })
    );
  }

  /**
   * Create document attached to thesis.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDocumentAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDocumentAsync$Plain(params?: {
    body?: {
'ThesisId': string;
'DocumentTypeCode': string;
'SpecialisationName': string;
'ProjectCode': string;
'FileToUpload': Blob;
}
  },
  context?: HttpContext

): Observable<DocumentResponse> {

    return this.createDocumentAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentResponse>) => r.body as DocumentResponse)
    );
  }

  /**
   * Create document attached to thesis.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDocumentAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDocumentAsync$Response(params?: {
    body?: {
'ThesisId': string;
'DocumentTypeCode': string;
'SpecialisationName': string;
'ProjectCode': string;
'FileToUpload': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.CreateDocumentAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentResponse>;
      })
    );
  }

  /**
   * Create document attached to thesis.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDocumentAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDocumentAsync(params?: {
    body?: {
'ThesisId': string;
'DocumentTypeCode': string;
'SpecialisationName': string;
'ProjectCode': string;
'FileToUpload': Blob;
}
  },
  context?: HttpContext

): Observable<DocumentResponse> {

    return this.createDocumentAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentResponse>) => r.body as DocumentResponse)
    );
  }

  /**
   * Path part for operation deleteDocumentAsync
   */
  static readonly DeleteDocumentAsyncPath = '/Documents/{documentId}';

  /**
   * Delete document attached to thesis.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocumentAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumentAsync$Plain$Response(params: {
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.DeleteDocumentAsyncPath, 'delete');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete document attached to thesis.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocumentAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumentAsync$Plain(params: {
    documentId: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.deleteDocumentAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete document attached to thesis.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocumentAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumentAsync$Response(params: {
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.DeleteDocumentAsyncPath, 'delete');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete document attached to thesis.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocumentAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumentAsync(params: {
    documentId: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.deleteDocumentAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getDocumentsByThesisAndTypeAsync
   */
  static readonly GetDocumentsByThesisAndTypeAsyncPath = '/Documents/GetDocumentsByThesisAndTypeAsync';

  /**
   * Get documents of the thesis and document type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsByThesisAndTypeAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocumentsByThesisAndTypeAsync$Plain$Response(params?: {
    body?: GetDocumentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DocumentResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentsByThesisAndTypeAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentResponse>>;
      })
    );
  }

  /**
   * Get documents of the thesis and document type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentsByThesisAndTypeAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocumentsByThesisAndTypeAsync$Plain(params?: {
    body?: GetDocumentRequest
  },
  context?: HttpContext

): Observable<Array<DocumentResponse>> {

    return this.getDocumentsByThesisAndTypeAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DocumentResponse>>) => r.body as Array<DocumentResponse>)
    );
  }

  /**
   * Get documents of the thesis and document type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsByThesisAndTypeAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocumentsByThesisAndTypeAsync$Response(params?: {
    body?: GetDocumentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DocumentResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentsByThesisAndTypeAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentResponse>>;
      })
    );
  }

  /**
   * Get documents of the thesis and document type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentsByThesisAndTypeAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocumentsByThesisAndTypeAsync(params?: {
    body?: GetDocumentRequest
  },
  context?: HttpContext

): Observable<Array<DocumentResponse>> {

    return this.getDocumentsByThesisAndTypeAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DocumentResponse>>) => r.body as Array<DocumentResponse>)
    );
  }

  /**
   * Path part for operation createDocumentByThesisAndTypeAsync
   */
  static readonly CreateDocumentByThesisAndTypeAsyncPath = '/Documents/CreateDocumentByThesisAndTypeAsync';

  /**
   * Create document attached to thesis by specific document type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDocumentByThesisAndTypeAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDocumentByThesisAndTypeAsync$Plain$Response(params?: {
    body?: {
'ThesisId': string;
'CreatedBy': string;
'DocumentTypeCode': string;
'FileToUpload': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.CreateDocumentByThesisAndTypeAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentResponse>;
      })
    );
  }

  /**
   * Create document attached to thesis by specific document type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDocumentByThesisAndTypeAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDocumentByThesisAndTypeAsync$Plain(params?: {
    body?: {
'ThesisId': string;
'CreatedBy': string;
'DocumentTypeCode': string;
'FileToUpload': Blob;
}
  },
  context?: HttpContext

): Observable<DocumentResponse> {

    return this.createDocumentByThesisAndTypeAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentResponse>) => r.body as DocumentResponse)
    );
  }

  /**
   * Create document attached to thesis by specific document type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDocumentByThesisAndTypeAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDocumentByThesisAndTypeAsync$Response(params?: {
    body?: {
'ThesisId': string;
'CreatedBy': string;
'DocumentTypeCode': string;
'FileToUpload': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.CreateDocumentByThesisAndTypeAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentResponse>;
      })
    );
  }

  /**
   * Create document attached to thesis by specific document type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDocumentByThesisAndTypeAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDocumentByThesisAndTypeAsync(params?: {
    body?: {
'ThesisId': string;
'CreatedBy': string;
'DocumentTypeCode': string;
'FileToUpload': Blob;
}
  },
  context?: HttpContext

): Observable<DocumentResponse> {

    return this.createDocumentByThesisAndTypeAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentResponse>) => r.body as DocumentResponse)
    );
  }

}
