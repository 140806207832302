/* tslint:disable */
/* eslint-disable */
export enum EnumStepStatus {
  InProgress = 'InProgress',
  ValidationInProgress = 'ValidationInProgress',
  Refused = 'Refused',
  Validated = 'Validated',
  Waiting = 'Waiting',
  ArbitrationInProgress = 'ArbitrationInProgress'
}
