/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateThesisRequest } from '../models/create-or-update-thesis-request';
import { ThesisCoordinatorResponse } from '../models/thesis-coordinator-response';
import { ThesisDetailsSupervisionResponse } from '../models/thesis-details-supervision-response';
import { ThesisResponse } from '../models/thesis-response';
import { UpdateThesisStep4Request } from '../models/update-thesis-step-4-request';
import { UpdateThesisSupervisionRequest } from '../models/update-thesis-supervision-request';

@Injectable({
  providedIn: 'root',
})
export class ThesisService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createThesisAsync
   */
  static readonly CreateThesisAsyncPath = '/Thesis';

  /**
   * Create thesis with data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createThesisAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createThesisAsync$Plain$Response(params?: {
    body?: CreateOrUpdateThesisRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.CreateThesisAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisResponse>;
      })
    );
  }

  /**
   * Create thesis with data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createThesisAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createThesisAsync$Plain(params?: {
    body?: CreateOrUpdateThesisRequest
  },
  context?: HttpContext

): Observable<ThesisResponse> {

    return this.createThesisAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisResponse>) => r.body as ThesisResponse)
    );
  }

  /**
   * Create thesis with data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createThesisAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createThesisAsync$Response(params?: {
    body?: CreateOrUpdateThesisRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.CreateThesisAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisResponse>;
      })
    );
  }

  /**
   * Create thesis with data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createThesisAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createThesisAsync(params?: {
    body?: CreateOrUpdateThesisRequest
  },
  context?: HttpContext

): Observable<ThesisResponse> {

    return this.createThesisAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisResponse>) => r.body as ThesisResponse)
    );
  }

  /**
   * Path part for operation updateThesisStep1Async
   */
  static readonly UpdateThesisStep1AsyncPath = '/Thesis/{thesisId}/step1';

  /**
   * Update thesis step1.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateThesisStep1Async$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisStep1Async$Plain$Response(params: {
    thesisId: string;
    body?: CreateOrUpdateThesisRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.UpdateThesisStep1AsyncPath, 'put');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisResponse>;
      })
    );
  }

  /**
   * Update thesis step1.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateThesisStep1Async$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisStep1Async$Plain(params: {
    thesisId: string;
    body?: CreateOrUpdateThesisRequest
  },
  context?: HttpContext

): Observable<ThesisResponse> {

    return this.updateThesisStep1Async$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisResponse>) => r.body as ThesisResponse)
    );
  }

  /**
   * Update thesis step1.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateThesisStep1Async()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisStep1Async$Response(params: {
    thesisId: string;
    body?: CreateOrUpdateThesisRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.UpdateThesisStep1AsyncPath, 'put');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisResponse>;
      })
    );
  }

  /**
   * Update thesis step1.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateThesisStep1Async$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisStep1Async(params: {
    thesisId: string;
    body?: CreateOrUpdateThesisRequest
  },
  context?: HttpContext

): Observable<ThesisResponse> {

    return this.updateThesisStep1Async$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisResponse>) => r.body as ThesisResponse)
    );
  }

  /**
   * Path part for operation updateThesisStep4Async
   */
  static readonly UpdateThesisStep4AsyncPath = '/Thesis/{thesisId}/step4';

  /**
   * Update thesis step4.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateThesisStep4Async$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisStep4Async$Plain$Response(params: {
    thesisId: string;
    body?: UpdateThesisStep4Request
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.UpdateThesisStep4AsyncPath, 'put');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisResponse>;
      })
    );
  }

  /**
   * Update thesis step4.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateThesisStep4Async$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisStep4Async$Plain(params: {
    thesisId: string;
    body?: UpdateThesisStep4Request
  },
  context?: HttpContext

): Observable<ThesisResponse> {

    return this.updateThesisStep4Async$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisResponse>) => r.body as ThesisResponse)
    );
  }

  /**
   * Update thesis step4.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateThesisStep4Async()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisStep4Async$Response(params: {
    thesisId: string;
    body?: UpdateThesisStep4Request
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.UpdateThesisStep4AsyncPath, 'put');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisResponse>;
      })
    );
  }

  /**
   * Update thesis step4.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateThesisStep4Async$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisStep4Async(params: {
    thesisId: string;
    body?: UpdateThesisStep4Request
  },
  context?: HttpContext

): Observable<ThesisResponse> {

    return this.updateThesisStep4Async$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisResponse>) => r.body as ThesisResponse)
    );
  }

  /**
   * Path part for operation getAllThesis
   */
  static readonly GetAllThesisPath = '/Thesis/All';

  /**
   * Get all thesis for Coordinator dashboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllThesis$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllThesis$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ThesisCoordinatorResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.GetAllThesisPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ThesisCoordinatorResponse>>;
      })
    );
  }

  /**
   * Get all thesis for Coordinator dashboard.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllThesis$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllThesis$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ThesisCoordinatorResponse>> {

    return this.getAllThesis$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ThesisCoordinatorResponse>>) => r.body as Array<ThesisCoordinatorResponse>)
    );
  }

  /**
   * Get all thesis for Coordinator dashboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllThesis()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllThesis$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ThesisCoordinatorResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.GetAllThesisPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ThesisCoordinatorResponse>>;
      })
    );
  }

  /**
   * Get all thesis for Coordinator dashboard.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllThesis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllThesis(params?: {
  },
  context?: HttpContext

): Observable<Array<ThesisCoordinatorResponse>> {

    return this.getAllThesis$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ThesisCoordinatorResponse>>) => r.body as Array<ThesisCoordinatorResponse>)
    );
  }

  /**
   * Path part for operation getThesisDetailsSupervisionCoordinatorByThesisIdAsync
   */
  static readonly GetThesisDetailsSupervisionCoordinatorByThesisIdAsyncPath = '/Thesis/{thesisId}/DetailsSupervision';

  /**
   * Get details thesis supervision by thesis id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain$Response(params: {
    thesisId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisDetailsSupervisionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.GetThesisDetailsSupervisionCoordinatorByThesisIdAsyncPath, 'get');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisDetailsSupervisionResponse>;
      })
    );
  }

  /**
   * Get details thesis supervision by thesis id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain(params: {
    thesisId: string;
  },
  context?: HttpContext

): Observable<ThesisDetailsSupervisionResponse> {

    return this.getThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisDetailsSupervisionResponse>) => r.body as ThesisDetailsSupervisionResponse)
    );
  }

  /**
   * Get details thesis supervision by thesis id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getThesisDetailsSupervisionCoordinatorByThesisIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThesisDetailsSupervisionCoordinatorByThesisIdAsync$Response(params: {
    thesisId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisDetailsSupervisionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.GetThesisDetailsSupervisionCoordinatorByThesisIdAsyncPath, 'get');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisDetailsSupervisionResponse>;
      })
    );
  }

  /**
   * Get details thesis supervision by thesis id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getThesisDetailsSupervisionCoordinatorByThesisIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThesisDetailsSupervisionCoordinatorByThesisIdAsync(params: {
    thesisId: string;
  },
  context?: HttpContext

): Observable<ThesisDetailsSupervisionResponse> {

    return this.getThesisDetailsSupervisionCoordinatorByThesisIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisDetailsSupervisionResponse>) => r.body as ThesisDetailsSupervisionResponse)
    );
  }

  /**
   * Path part for operation updateThesisDetailsSupervisionCoordinatorByThesisIdAsync
   */
  static readonly UpdateThesisDetailsSupervisionCoordinatorByThesisIdAsyncPath = '/Thesis/{thesisId}/UpdateDetailsSupervision';

  /**
   * Update details thesis supervision by thesis id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain$Response(params: {
    thesisId: string;
    body?: UpdateThesisSupervisionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisDetailsSupervisionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.UpdateThesisDetailsSupervisionCoordinatorByThesisIdAsyncPath, 'put');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisDetailsSupervisionResponse>;
      })
    );
  }

  /**
   * Update details thesis supervision by thesis id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain(params: {
    thesisId: string;
    body?: UpdateThesisSupervisionRequest
  },
  context?: HttpContext

): Observable<ThesisDetailsSupervisionResponse> {

    return this.updateThesisDetailsSupervisionCoordinatorByThesisIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisDetailsSupervisionResponse>) => r.body as ThesisDetailsSupervisionResponse)
    );
  }

  /**
   * Update details thesis supervision by thesis id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateThesisDetailsSupervisionCoordinatorByThesisIdAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisDetailsSupervisionCoordinatorByThesisIdAsync$Response(params: {
    thesisId: string;
    body?: UpdateThesisSupervisionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisDetailsSupervisionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThesisService.UpdateThesisDetailsSupervisionCoordinatorByThesisIdAsyncPath, 'put');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisDetailsSupervisionResponse>;
      })
    );
  }

  /**
   * Update details thesis supervision by thesis id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateThesisDetailsSupervisionCoordinatorByThesisIdAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateThesisDetailsSupervisionCoordinatorByThesisIdAsync(params: {
    thesisId: string;
    body?: UpdateThesisSupervisionRequest
  },
  context?: HttpContext

): Observable<ThesisDetailsSupervisionResponse> {

    return this.updateThesisDetailsSupervisionCoordinatorByThesisIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisDetailsSupervisionResponse>) => r.body as ThesisDetailsSupervisionResponse)
    );
  }

}
