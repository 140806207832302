/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateSupervisionRequest } from '../models/create-or-update-supervision-request';
import { SupervisionResponse } from '../models/supervision-response';

@Injectable({
  providedIn: 'root',
})
export class SupervisionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createSupervisionAsync
   */
  static readonly CreateSupervisionAsyncPath = '/Supervisions';

  /**
   * Create supervision request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSupervisionAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSupervisionAsync$Plain$Response(params?: {
    body?: CreateOrUpdateSupervisionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupervisionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisionsService.CreateSupervisionAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupervisionResponse>;
      })
    );
  }

  /**
   * Create supervision request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSupervisionAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSupervisionAsync$Plain(params?: {
    body?: CreateOrUpdateSupervisionRequest
  },
  context?: HttpContext

): Observable<SupervisionResponse> {

    return this.createSupervisionAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupervisionResponse>) => r.body as SupervisionResponse)
    );
  }

  /**
   * Create supervision request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSupervisionAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSupervisionAsync$Response(params?: {
    body?: CreateOrUpdateSupervisionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupervisionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisionsService.CreateSupervisionAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupervisionResponse>;
      })
    );
  }

  /**
   * Create supervision request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSupervisionAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSupervisionAsync(params?: {
    body?: CreateOrUpdateSupervisionRequest
  },
  context?: HttpContext

): Observable<SupervisionResponse> {

    return this.createSupervisionAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupervisionResponse>) => r.body as SupervisionResponse)
    );
  }

  /**
   * Path part for operation updateSupervisionByIdAsync
   */
  static readonly UpdateSupervisionByIdAsyncPath = '/Supervisions/{supervisionId}';

  /**
   * Update supervision request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSupervisionByIdAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSupervisionByIdAsync$Plain$Response(params: {
    supervisionId: string;
    body?: CreateOrUpdateSupervisionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupervisionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisionsService.UpdateSupervisionByIdAsyncPath, 'put');
    if (params) {
      rb.path('supervisionId', params.supervisionId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupervisionResponse>;
      })
    );
  }

  /**
   * Update supervision request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSupervisionByIdAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSupervisionByIdAsync$Plain(params: {
    supervisionId: string;
    body?: CreateOrUpdateSupervisionRequest
  },
  context?: HttpContext

): Observable<SupervisionResponse> {

    return this.updateSupervisionByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupervisionResponse>) => r.body as SupervisionResponse)
    );
  }

  /**
   * Update supervision request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSupervisionByIdAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSupervisionByIdAsync$Response(params: {
    supervisionId: string;
    body?: CreateOrUpdateSupervisionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupervisionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisionsService.UpdateSupervisionByIdAsyncPath, 'put');
    if (params) {
      rb.path('supervisionId', params.supervisionId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupervisionResponse>;
      })
    );
  }

  /**
   * Update supervision request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSupervisionByIdAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSupervisionByIdAsync(params: {
    supervisionId: string;
    body?: CreateOrUpdateSupervisionRequest
  },
  context?: HttpContext

): Observable<SupervisionResponse> {

    return this.updateSupervisionByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupervisionResponse>) => r.body as SupervisionResponse)
    );
  }

}
