interface LanguageIcon {
  selectedValue: string;
  unSelectedValue: string;
}

export const frenchIcon: LanguageIcon = {
  selectedValue: 'assets/img/fr-selected-icon.svg',
  unSelectedValue: 'assets/img/fr-unselected-icon.svg',
};

export const ukIcon: LanguageIcon = {
  selectedValue: 'assets/img/uk-selected-icon.svg',
  unSelectedValue: 'assets/img/uk-unselected-icon.svg',
};
