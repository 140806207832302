<header class="header">
  <div class="header-logo show_element">
    <button (click)="goHome()">
      <img src="assets/img/logo.svg" height="60" width="160" />
    </button>
  </div>
  <div class="header-informations" [class.coordinator]="coordinatorMode">
    <div *ngIf="!coordinatorMode && studentSpecialisation && studentSpecialisation !== specialisationEnum.EDI" class="header-projects">
      <button class="btn-projects" mat-stroked-button>
        {{ 'CATALOG' | translate }}
      </button>
    </div>
    <div *ngIf="coordinatorMode" class="coordinator-menu show_element">
      <button mat-stroked-button [color]="'primary'" *ngFor="let item of coordinatorMenu" (click)="gotoPage(item)">{{ item | translate }}</button>
    </div>
    <div class="other-menu show_element">
      <div *ngIf="!coordinatorMode" class="header-languages">
        <app-language-switch></app-language-switch>
      </div>
      <div *ngIf="!coordinatorMode" class="separator"></div>
      <div class="header-profile">
        <app-profilemenu></app-profilemenu>
      </div>
    </div>
  </div>
</header>
