import { BrowserCacheLocation } from '@azure/msal-browser';
import { environmentModel } from '../app/core/models/environment.model';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const environment: environmentModel = {
  production: true,
  mode: 'integration',
  baseUri: '#{baseUri}#',
  apiUri: '#{apiUri}#',
  authentication: {
    clientId: '#{clientId}#',
    redirectUri: '#{redirectUri}#',
    authority: '#{authority}#',
    postLogoutRedirectUri: '#{redirectUri}#',
    navigateToLoginRequestUrl: true,
  },
  appInsights: {
    instrumentationKey: '#{instrumentationKey}#',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  scope: '#{scope}#',
};
