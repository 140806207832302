<div id="app-wrapper">
  <div *ngIf="testMode && currentStudent.id" class="mode-test">
    <span class="title">Test Mode</span>
    <div *ngIf="currentEnv == 'dev'">
      <p>
        <app-button [isValid]="true" name="Supprimer la thèse" (clickCallbackFn)="deleteThesis()"></app-button>
      </p>
      <p>
        <app-button [isValid]="true" name="Valider l'étape et passer à la suivante" (clickCallbackFn)="validateStep()"></app-button>
      </p>
      <p>
        <app-button [isValid]="true" name="Refuser l'étape" (clickCallbackFn)="refuseStep()"></app-button>
      </p>
      <p>
        <app-button [isValid]="true" name="Parcours Classique" (clickCallbackFn)="switchSpecialization(Specialisation.CLASSIQUE)"></app-button>
      </p>
      <p>
        <app-button [isValid]="true" name="Parcours Alternance" (clickCallbackFn)="switchSpecialization(Specialisation.ALTERNANCE)"></app-button>
      </p>
    </div>
    <p>
      <app-button [isValid]="true" name="Parcours EDI" (clickCallbackFn)="switchSpecialization(Specialisation.EDI)"></app-button>
    </p>
    <p class="result">
      {{ operationResult }}
    </p>
  </div>
  <app-header></app-header>
  <main id="main-container">
    <router-outlet></router-outlet>
  </main>
</div>
