/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateSemesterRequest } from '../models/create-or-update-semester-request';
import { SemesterResponse } from '../models/semester-response';
import { SemesterSearchResponse } from '../models/semester-search-response';

@Injectable({
  providedIn: 'root',
})
export class SemestersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCurrentSemesterBySpecialisationAndProjectIdAsync
   */
  static readonly GetCurrentSemesterBySpecialisationAndProjectIdAsyncPath = '/Semesters/current';

  /**
   * Get current semester by specialisation and project id
   * Used by front during register step (classic/alternant) or import aurion.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentSemesterBySpecialisationAndProjectIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSemesterBySpecialisationAndProjectIdAsync$Plain$Response(params?: {
    specialisationId?: string;
    projectId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SemesterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SemestersService.GetCurrentSemesterBySpecialisationAndProjectIdAsyncPath, 'get');
    if (params) {
      rb.query('specialisationId', params.specialisationId, {});
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SemesterResponse>;
      })
    );
  }

  /**
   * Get current semester by specialisation and project id
   * Used by front during register step (classic/alternant) or import aurion.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentSemesterBySpecialisationAndProjectIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSemesterBySpecialisationAndProjectIdAsync$Plain(params?: {
    specialisationId?: string;
    projectId?: string;
  },
  context?: HttpContext

): Observable<SemesterResponse> {

    return this.getCurrentSemesterBySpecialisationAndProjectIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SemesterResponse>) => r.body as SemesterResponse)
    );
  }

  /**
   * Get current semester by specialisation and project id
   * Used by front during register step (classic/alternant) or import aurion.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentSemesterBySpecialisationAndProjectIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSemesterBySpecialisationAndProjectIdAsync$Response(params?: {
    specialisationId?: string;
    projectId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SemesterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SemestersService.GetCurrentSemesterBySpecialisationAndProjectIdAsyncPath, 'get');
    if (params) {
      rb.query('specialisationId', params.specialisationId, {});
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SemesterResponse>;
      })
    );
  }

  /**
   * Get current semester by specialisation and project id
   * Used by front during register step (classic/alternant) or import aurion.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentSemesterBySpecialisationAndProjectIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSemesterBySpecialisationAndProjectIdAsync(params?: {
    specialisationId?: string;
    projectId?: string;
  },
  context?: HttpContext

): Observable<SemesterResponse> {

    return this.getCurrentSemesterBySpecialisationAndProjectIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<SemesterResponse>) => r.body as SemesterResponse)
    );
  }

  /**
   * Path part for operation getSemesterByIdAsync
   */
  static readonly GetSemesterByIdAsyncPath = '/Semesters/{semesterId}';

  /**
   * Get semester by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSemesterByIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSemesterByIdAsync$Plain$Response(params: {
    semesterId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SemesterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SemestersService.GetSemesterByIdAsyncPath, 'get');
    if (params) {
      rb.path('semesterId', params.semesterId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SemesterResponse>;
      })
    );
  }

  /**
   * Get semester by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSemesterByIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSemesterByIdAsync$Plain(params: {
    semesterId: string;
  },
  context?: HttpContext

): Observable<SemesterResponse> {

    return this.getSemesterByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SemesterResponse>) => r.body as SemesterResponse)
    );
  }

  /**
   * Get semester by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSemesterByIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSemesterByIdAsync$Response(params: {
    semesterId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SemesterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SemestersService.GetSemesterByIdAsyncPath, 'get');
    if (params) {
      rb.path('semesterId', params.semesterId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SemesterResponse>;
      })
    );
  }

  /**
   * Get semester by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSemesterByIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSemesterByIdAsync(params: {
    semesterId: string;
  },
  context?: HttpContext

): Observable<SemesterResponse> {

    return this.getSemesterByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<SemesterResponse>) => r.body as SemesterResponse)
    );
  }

  /**
   * Path part for operation updateSemesterByIdAsync
   */
  static readonly UpdateSemesterByIdAsyncPath = '/Semesters/{semesterId}';

  /**
   * Update semester and semesterStep by semester id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSemesterByIdAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSemesterByIdAsync$Plain$Response(params: {
    semesterId: string;
    body?: CreateOrUpdateSemesterRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SemesterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SemestersService.UpdateSemesterByIdAsyncPath, 'put');
    if (params) {
      rb.path('semesterId', params.semesterId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SemesterResponse>;
      })
    );
  }

  /**
   * Update semester and semesterStep by semester id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSemesterByIdAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSemesterByIdAsync$Plain(params: {
    semesterId: string;
    body?: CreateOrUpdateSemesterRequest
  },
  context?: HttpContext

): Observable<SemesterResponse> {

    return this.updateSemesterByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SemesterResponse>) => r.body as SemesterResponse)
    );
  }

  /**
   * Update semester and semesterStep by semester id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSemesterByIdAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSemesterByIdAsync$Response(params: {
    semesterId: string;
    body?: CreateOrUpdateSemesterRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SemesterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SemestersService.UpdateSemesterByIdAsyncPath, 'put');
    if (params) {
      rb.path('semesterId', params.semesterId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SemesterResponse>;
      })
    );
  }

  /**
   * Update semester and semesterStep by semester id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSemesterByIdAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSemesterByIdAsync(params: {
    semesterId: string;
    body?: CreateOrUpdateSemesterRequest
  },
  context?: HttpContext

): Observable<SemesterResponse> {

    return this.updateSemesterByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<SemesterResponse>) => r.body as SemesterResponse)
    );
  }

  /**
   * Path part for operation searchSemestersBySpecialisationAndProjectIdAsync
   */
  static readonly SearchSemestersBySpecialisationAndProjectIdAsyncPath = '/Semesters/search';

  /**
   * Search semesters by specialisationId, projectId and semester.EndDate => DateTime.Now.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSemestersBySpecialisationAndProjectIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSemestersBySpecialisationAndProjectIdAsync$Plain$Response(params?: {
    specialisationId?: string;
    projectId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SemesterSearchResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SemestersService.SearchSemestersBySpecialisationAndProjectIdAsyncPath, 'get');
    if (params) {
      rb.query('specialisationId', params.specialisationId, {});
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SemesterSearchResponse>>;
      })
    );
  }

  /**
   * Search semesters by specialisationId, projectId and semester.EndDate => DateTime.Now.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchSemestersBySpecialisationAndProjectIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSemestersBySpecialisationAndProjectIdAsync$Plain(params?: {
    specialisationId?: string;
    projectId?: string;
  },
  context?: HttpContext

): Observable<Array<SemesterSearchResponse>> {

    return this.searchSemestersBySpecialisationAndProjectIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SemesterSearchResponse>>) => r.body as Array<SemesterSearchResponse>)
    );
  }

  /**
   * Search semesters by specialisationId, projectId and semester.EndDate => DateTime.Now.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSemestersBySpecialisationAndProjectIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSemestersBySpecialisationAndProjectIdAsync$Response(params?: {
    specialisationId?: string;
    projectId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SemesterSearchResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SemestersService.SearchSemestersBySpecialisationAndProjectIdAsyncPath, 'get');
    if (params) {
      rb.query('specialisationId', params.specialisationId, {});
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SemesterSearchResponse>>;
      })
    );
  }

  /**
   * Search semesters by specialisationId, projectId and semester.EndDate => DateTime.Now.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchSemestersBySpecialisationAndProjectIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSemestersBySpecialisationAndProjectIdAsync(params?: {
    specialisationId?: string;
    projectId?: string;
  },
  context?: HttpContext

): Observable<Array<SemesterSearchResponse>> {

    return this.searchSemestersBySpecialisationAndProjectIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SemesterSearchResponse>>) => r.body as Array<SemesterSearchResponse>)
    );
  }

}
