import { Inject, Injectable } from '@angular/core';
import {
  IPublicClientApplication,
  AccountInfo,
  EndSessionRequest,
  AuthorizationUrlRequest,
  AuthenticationResult,
  RedirectRequest,
  SilentRequest,
  PopupRequest,
} from '@azure/msal-browser';
import { Observable, from } from 'rxjs';
import { Location } from '@angular/common';
import { IAuthenticationService } from './IAuthenticationService';
import { MSAL_INSTANCE } from '@azure/msal-angular';

@Injectable()
export class AuthenticationService implements IAuthenticationService {
  private redirectHash = '';

  constructor(
    @Inject(MSAL_INSTANCE)
    public instance: IPublicClientApplication,
    private location: Location
  ) {
    // Cache the code hash before Angular router clears it
    const hash = this.location.path(true).split('#').pop();
    if (hash) {
      this.redirectHash = `#${hash}`;
    }
  }

  acquireTokenPopup(request: PopupRequest): Observable<AuthenticationResult> {
    return from(this.instance.acquireTokenPopup(request));
  }
  acquireTokenRedirect(request: RedirectRequest): Observable<void> {
    return from(this.instance.acquireTokenRedirect(request));
  }
  acquireTokenSilent(silentRequest: SilentRequest): Observable<AuthenticationResult> {
    return from(this.instance.acquireTokenSilent(silentRequest));
  }
  getAllAccounts(): AccountInfo[] {
    return this.instance.getAllAccounts();
  }
  logoutRedirect(request?: EndSessionRequest): Observable<void> {
    return from(this.instance.logoutRedirect(request));
  }
  logout(logoutRequest?: EndSessionRequest): Observable<void> {
    return from(this.instance.logout(logoutRequest));
  }
}
