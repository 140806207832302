import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { AuthenticationService } from '@coreServices/authenticationService/authentication.service';
import { Store } from '@ngrx/store';
import { filter, forkJoin, map, of, switchMap, take, tap } from 'rxjs';
import { UserState } from 'src/store/user/user.states';
import * as userReducer from 'src/store/user/user.reducer';
import { UserResponse } from '@sharedModels/user-response';
import { MonitoringService } from '@coreServices/monitoringService/monitoring.service';
import { UsersService } from '@sharedServices/users.service';
import { environment } from 'src/environments/environment';
import GlobalStorageService from '@coreServices/globalStorageService';
import { UserActions } from 'src/store/user/user.actions';
import { StudentsService } from '@sharedServices/students.service';
import { ProfessorsService } from '@sharedServices/professors.service';
import { Roles } from '@sharedEnums/roles.enum';
import { StudentState } from 'src/store/student/student.states';
import { SupervisorState } from 'src/store/supervisor/supervisor.states';
import { StudentActions } from 'src/store/student/student.actions';
import { SupervisorActions } from 'src/store/supervisor/supervisor.actions';
import { COORDINATOR_ROLES, SUPERVISOR_ROLES } from './roles.guard';
import { CoordinatorState } from 'src/store/coordinator/coordinator.states';
import { CoordinatorsService } from '@sharedServices/coordinators.service';
import { CoordinatorActions } from 'src/store/coordinator/coordinator.actions';

/**
 * Acts as an authentication guard.
 * * This method satisfies the `CanActivateFn` type from Angular's router.
 * * See https://angular.io/api/router/CanActivateFn
 *
 * @return An observable that emits a boolean indicating whether the user can access the route.
 */
export function AuthGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  broadcastService = inject(MsalBroadcastService),
  userStore = inject(Store<UserState>),
  authenticationService = inject(AuthenticationService),
  monitoringService = inject(MonitoringService),
  usersService = inject(UsersService),
  globalStorageService = inject(GlobalStorageService),
  studentsService = inject(StudentsService),
  professorsService = inject(ProfessorsService),
  coordinatorsService = inject(CoordinatorsService),
  studentStore = inject(Store<StudentState>),
  supervisorStore = inject(Store<SupervisorState>),
  coordinatorStore = inject(Store<CoordinatorState>),
  router = inject(Router)
) {
  return broadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    take(1),
    switchMap(() => {
      const account = authenticationService.instance.getAllAccounts()[0];
      if (!account) {
        monitoringService.logEvent('user is not logged');
        return of(false);
      }

      monitoringService.logEvent('user is logged');

      // If there is an account, we check the user and get his roles.
      return checkUser(userStore, account, authenticationService, globalStorageService, usersService).pipe(
        switchMap(user => {
          // If user is null, it means the user is already in store so no need to get user role again.
          if (!user) {
            return of(true);
          }

          return getUserRole(user, studentsService, coordinatorsService, studentStore, supervisorStore, coordinatorStore, professorsService).pipe(
            map(() => {
              // Navigate to url in state to not lose user navigation
              return router.parseUrl(state.url);
            })
          );
        })
      );
    })
  );
}

/**
 * Checks the user's information and retrieves the user data from the user store.
 * * If user is not present in the user store, it will going to get the authentication token then get user.
 * * Else it will return null.
 *
 * @param userStore - The store that holds the user state.
 * @param account - The account information of the user.
 * @param authenticationService - The service responsible for authentication.
 * @param globalStorageService - The service responsible for global storage.
 * @param usersService - The service responsible for managing users.
 * @return An observable that emits the user data or null.
 */
function checkUser(
  userStore: Store<UserState>,
  account: AccountInfo,
  authenticationService: AuthenticationService,
  globalStorageService: GlobalStorageService,
  usersService: UsersService
) {
  return userStore.select(userReducer.getUser).pipe(
    take(1),
    switchMap(user => {
      if (!user || !user.id) {
        // Get token then get user and return user
        return forkJoin([getToken(authenticationService, account, globalStorageService), getUser(usersService, userStore)]).pipe(map(([_, user]) => user));
      } else {
        return of(null);
      }
    })
  );
}

/**
 * Retrieves a token using the authentication service and account information and store it in the global storage.
 *
 * @param authenticationService - The authentication service used to acquire the token.
 * @param account - The account information used to acquire the token.
 * @param globalStorageService - The global storage service used to store the token.
 * @return An observable that emits when the token is successfully acquired and stored.
 */
function getToken(authenticationService: AuthenticationService, account: AccountInfo, globalStorageService: GlobalStorageService) {
  const accessTokenRequest = {
    scopes: [environment.scope],
    account,
  };
  return authenticationService.acquireTokenSilent(accessTokenRequest).pipe(map(result => globalStorageService.setToken(result.accessToken)));
}

/**
 * Retrieves a user from the users service and dispatches it to the user store.
 *
 * @param usersService - The service used to retrieve the user.
 * @param userStore - The store used to dispatch the user.
 * @return An observable that emits the user.
 */
function getUser(usersService: UsersService, userStore: Store<UserState>) {
  return usersService.getUserAsync().pipe(
    tap(user => {
      if (user) {
        userStore.dispatch(UserActions.setUser(user));
      }
    })
  );
}

/**
 * Saves the user by his roles.
 *
 * @param user - The user response object containing user information.
 * @param studentsService - The service responsible for handling student-related operations.
 * @param studentStore - The store for managing student state.
 * @param supervisorStore - The store for managing supervisor state.
 * @param professorsService - The service responsible for handling professor-related operations.
 * @return An observable that emits a boolean indicating whether the user role was successfully retrieved.
 */
function getUserRole(
  user: UserResponse,
  studentsService: StudentsService,
  coordinatorsService: CoordinatorsService,
  studentStore: Store<StudentState>,
  supervisorStore: Store<SupervisorState>,
  coordinatorStore: Store<CoordinatorState>,
  professorsService: ProfessorsService
) {
  if (user.roles?.some(role => role.userRole === Roles.Student)) {
    return saveStudent(studentsService, studentStore);
  }

  if (user.roles?.some(role => SUPERVISOR_ROLES.includes(role.userRole as Roles))) {
    return saveSupervisor(professorsService, supervisorStore);
  }

  if (user.roles?.some(role => COORDINATOR_ROLES.includes(role.userRole as Roles))) {
    return saveCoordinator(coordinatorsService, coordinatorStore);
  }

  return of(false);
}

/**
 * Saves the student by dispatching the student to the student store.
 *
 * @param studentsService - The service for retrieving connected student.
 * @param studentStore - The store for managing student state.
 * @return An observable that emits a boolean indicating if the student exists.
 */
function saveStudent(studentsService: StudentsService, studentStore: Store<StudentState>) {
  return studentsService.getStudentConnectedAsync().pipe(
    map(student => {
      if (student) {
        studentStore.dispatch(StudentActions.setStudentData(student));
      }

      return !!student;
    })
  );
}

/**
 * Saves the supervisor by dispatching the professor to the supervisor store.
 *
 * @param professorsService - The service for retrieving connected professor.
 * @param supervisorStore - The store for managing supervisor state.
 * @return An observable that emits a boolean indicating if the professor exists.
 */
function saveSupervisor(professorsService: ProfessorsService, supervisorStore: Store<SupervisorState>) {
  return professorsService.getProfessorConnectedAsync().pipe(
    map(professor => {
      if (professor) {
        supervisorStore.dispatch(SupervisorActions.setSupervisor(professor));
      }

      return !!professor;
    })
  );
}

/**
 * Saves the coordinator by dispatching into the coordinator store.
 *
 * @param professorsService - The service for retrieving connected professor.
 * @param supervisorStore - The store for managing supervisor state.
 * @return An observable that emits a boolean indicating if the professor exists.
 */
function saveCoordinator(coordinatorsService: CoordinatorsService, coordinatorStore: Store<CoordinatorState>) {
  return coordinatorsService.getCoordinatorConnectedAsync().pipe(
    map(coordinator => {
      if (coordinator) {
        coordinatorStore.dispatch(CoordinatorActions.setCoordinator(coordinator));
      }

      return !!coordinator;
    })
  );
}
