import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/store/user/user.states';
import * as userReducer from 'src/store/user/user.reducer';
import { AuthenticationService } from '@coreServices/authenticationService/authentication.service';
import { Subscription, map } from 'rxjs';
import { AutoUnsubscribe } from '../../decorators/autoUnsubscribe';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
@AutoUnsubscribe()
export class ProfileMenuComponent implements OnInit {
  userExist = false;
  loading = true;
  fullName = '';
  initials = '';
  getUserSubscribe = new Subscription();

  constructor(private userStore: Store<UserState>, private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.getUserSubscribe.add(
      this.userStore
        .select(userReducer.getUser)
        .pipe(
          map(result => {
            if (result && result.firstName) {
              this.fullName = `${result.firstName} ${result.lastName}`;
              this.initials = `${result.firstName?.charAt(0)}${result.lastName?.charAt(0)}`;
              this.userExist = true;
              this.loading = false;
            }
          })
        )
        .subscribe()
    );
  }

  logout(): void {
    this.authenticationService.logoutRedirect();
  }
}
