import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MsalInterceptorConfiguration } from './msal.interceptor.configuration';
import { AuthenticationService } from './authentication.service';
import { MsalInterceptor } from './msal.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, MsalModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: () => new PublicClientApplication({ auth: environment.authentication }),
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect,
      } as MsalGuardConfiguration,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: () =>
        ({
          interactionType: InteractionType.Redirect,
          protectedResourceMap: new Map<string, Array<string>>([[environment.apiUri, [environment.scope]]]),
        } as MsalInterceptorConfiguration),
    },
    AuthenticationService,
    MsalService,
    MsalBroadcastService,
    MsalGuard,
  ],
})
export class AuthenticationModule {}
