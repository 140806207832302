/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExaminationQuestionCategoryResponse } from '../models/examination-question-category-response';

@Injectable({
  providedIn: 'root',
})
export class ExaminationQuestionCategoriesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync
   */
  static readonly GetAllExaminationQuestionCategoriesBySpecialisationAndProgramAsyncPath = '/ExaminationQuestionCategories/{specialisationId}/{programCode}';

  /**
   * Get all examination question categories according to the specialisationId and programId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync$Plain$Response(params: {
    specialisationId: string;
    programCode: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ExaminationQuestionCategoryResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationQuestionCategoriesService.GetAllExaminationQuestionCategoriesBySpecialisationAndProgramAsyncPath, 'get');
    if (params) {
      rb.path('specialisationId', params.specialisationId, {});
      rb.path('programCode', params.programCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExaminationQuestionCategoryResponse>>;
      })
    );
  }

  /**
   * Get all examination question categories according to the specialisationId and programId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync$Plain(params: {
    specialisationId: string;
    programCode: string;
  },
  context?: HttpContext

): Observable<Array<ExaminationQuestionCategoryResponse>> {

    return this.getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ExaminationQuestionCategoryResponse>>) => r.body as Array<ExaminationQuestionCategoryResponse>)
    );
  }

  /**
   * Get all examination question categories according to the specialisationId and programId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync$Response(params: {
    specialisationId: string;
    programCode: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ExaminationQuestionCategoryResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationQuestionCategoriesService.GetAllExaminationQuestionCategoriesBySpecialisationAndProgramAsyncPath, 'get');
    if (params) {
      rb.path('specialisationId', params.specialisationId, {});
      rb.path('programCode', params.programCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExaminationQuestionCategoryResponse>>;
      })
    );
  }

  /**
   * Get all examination question categories according to the specialisationId and programId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync(params: {
    specialisationId: string;
    programCode: string;
  },
  context?: HttpContext

): Observable<Array<ExaminationQuestionCategoryResponse>> {

    return this.getAllExaminationQuestionCategoriesBySpecialisationAndProgramAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ExaminationQuestionCategoryResponse>>) => r.body as Array<ExaminationQuestionCategoryResponse>)
    );
  }

}
