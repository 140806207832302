/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ArbitrationResponse } from '../models/arbitration-response';
import { CreateOrUpdateArbitrationRequest } from '../models/create-or-update-arbitration-request';

@Injectable({
  providedIn: 'root',
})
export class ArbitrationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getArbitrationByIdAsync
   */
  static readonly GetArbitrationByIdAsyncPath = '/Arbitrations';

  /**
   * Get arbitration by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArbitrationByIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbitrationByIdAsync$Plain$Response(params?: {
    arbitrationId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ArbitrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ArbitrationsService.GetArbitrationByIdAsyncPath, 'get');
    if (params) {
      rb.query('arbitrationId', params.arbitrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArbitrationResponse>;
      })
    );
  }

  /**
   * Get arbitration by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArbitrationByIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbitrationByIdAsync$Plain(params?: {
    arbitrationId?: string;
  },
  context?: HttpContext

): Observable<ArbitrationResponse> {

    return this.getArbitrationByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ArbitrationResponse>) => r.body as ArbitrationResponse)
    );
  }

  /**
   * Get arbitration by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArbitrationByIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbitrationByIdAsync$Response(params?: {
    arbitrationId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ArbitrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ArbitrationsService.GetArbitrationByIdAsyncPath, 'get');
    if (params) {
      rb.query('arbitrationId', params.arbitrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArbitrationResponse>;
      })
    );
  }

  /**
   * Get arbitration by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArbitrationByIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbitrationByIdAsync(params?: {
    arbitrationId?: string;
  },
  context?: HttpContext

): Observable<ArbitrationResponse> {

    return this.getArbitrationByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ArbitrationResponse>) => r.body as ArbitrationResponse)
    );
  }

  /**
   * Path part for operation updateArbitrationByIdAsync
   */
  static readonly UpdateArbitrationByIdAsyncPath = '/Arbitrations';

  /**
   * Update arbitration by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArbitrationByIdAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArbitrationByIdAsync$Plain$Response(params?: {
    arbitrationId?: string;
    body?: CreateOrUpdateArbitrationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ArbitrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ArbitrationsService.UpdateArbitrationByIdAsyncPath, 'put');
    if (params) {
      rb.query('arbitrationId', params.arbitrationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArbitrationResponse>;
      })
    );
  }

  /**
   * Update arbitration by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArbitrationByIdAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArbitrationByIdAsync$Plain(params?: {
    arbitrationId?: string;
    body?: CreateOrUpdateArbitrationRequest
  },
  context?: HttpContext

): Observable<ArbitrationResponse> {

    return this.updateArbitrationByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ArbitrationResponse>) => r.body as ArbitrationResponse)
    );
  }

  /**
   * Update arbitration by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArbitrationByIdAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArbitrationByIdAsync$Response(params?: {
    arbitrationId?: string;
    body?: CreateOrUpdateArbitrationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ArbitrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ArbitrationsService.UpdateArbitrationByIdAsyncPath, 'put');
    if (params) {
      rb.query('arbitrationId', params.arbitrationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArbitrationResponse>;
      })
    );
  }

  /**
   * Update arbitration by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArbitrationByIdAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArbitrationByIdAsync(params?: {
    arbitrationId?: string;
    body?: CreateOrUpdateArbitrationRequest
  },
  context?: HttpContext

): Observable<ArbitrationResponse> {

    return this.updateArbitrationByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ArbitrationResponse>) => r.body as ArbitrationResponse)
    );
  }

  /**
   * Path part for operation createArbitrationAsync
   */
  static readonly CreateArbitrationAsyncPath = '/Arbitrations';

  /**
   * Create arbitration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArbitrationAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createArbitrationAsync$Plain$Response(params?: {
    body?: CreateOrUpdateArbitrationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ArbitrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ArbitrationsService.CreateArbitrationAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArbitrationResponse>;
      })
    );
  }

  /**
   * Create arbitration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createArbitrationAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createArbitrationAsync$Plain(params?: {
    body?: CreateOrUpdateArbitrationRequest
  },
  context?: HttpContext

): Observable<ArbitrationResponse> {

    return this.createArbitrationAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ArbitrationResponse>) => r.body as ArbitrationResponse)
    );
  }

  /**
   * Create arbitration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArbitrationAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createArbitrationAsync$Response(params?: {
    body?: CreateOrUpdateArbitrationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ArbitrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ArbitrationsService.CreateArbitrationAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArbitrationResponse>;
      })
    );
  }

  /**
   * Create arbitration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createArbitrationAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createArbitrationAsync(params?: {
    body?: CreateOrUpdateArbitrationRequest
  },
  context?: HttpContext

): Observable<ArbitrationResponse> {

    return this.createArbitrationAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ArbitrationResponse>) => r.body as ArbitrationResponse)
    );
  }

}
