/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProfessorSearchResponse } from '../models/professor-search-response';
import { ProfessorThesisInformationsResponse } from '../models/professor-thesis-informations-response';
import { SupervisorResponse } from '../models/supervisor-response';

@Injectable({
  providedIn: 'root',
})
export class ProfessorsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getProfessorConnectedAsync
   */
  static readonly GetProfessorConnectedAsyncPath = '/Professors';

  /**
   * Get professor connected.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfessorConnectedAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorConnectedAsync$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupervisorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetProfessorConnectedAsyncPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupervisorResponse>;
      })
    );
  }

  /**
   * Get professor connected.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfessorConnectedAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorConnectedAsync$Plain(params?: {
  },
  context?: HttpContext

): Observable<SupervisorResponse> {

    return this.getProfessorConnectedAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupervisorResponse>) => r.body as SupervisorResponse)
    );
  }

  /**
   * Get professor connected.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfessorConnectedAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorConnectedAsync$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupervisorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetProfessorConnectedAsyncPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupervisorResponse>;
      })
    );
  }

  /**
   * Get professor connected.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfessorConnectedAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorConnectedAsync(params?: {
  },
  context?: HttpContext

): Observable<SupervisorResponse> {

    return this.getProfessorConnectedAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupervisorResponse>) => r.body as SupervisorResponse)
    );
  }

  /**
   * Path part for operation getProfessorsBySectorIdAsync
   */
  static readonly GetProfessorsBySectorIdAsyncPath = '/Professors/search/sector/{sectorId}';

  /**
   * Get all professors according to the sectorId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfessorsBySectorIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorsBySectorIdAsync$Plain$Response(params: {
    sectorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProfessorSearchResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetProfessorsBySectorIdAsyncPath, 'get');
    if (params) {
      rb.path('sectorId', params.sectorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfessorSearchResponse>>;
      })
    );
  }

  /**
   * Get all professors according to the sectorId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfessorsBySectorIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorsBySectorIdAsync$Plain(params: {
    sectorId: string;
  },
  context?: HttpContext

): Observable<Array<ProfessorSearchResponse>> {

    return this.getProfessorsBySectorIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProfessorSearchResponse>>) => r.body as Array<ProfessorSearchResponse>)
    );
  }

  /**
   * Get all professors according to the sectorId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfessorsBySectorIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorsBySectorIdAsync$Response(params: {
    sectorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProfessorSearchResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetProfessorsBySectorIdAsyncPath, 'get');
    if (params) {
      rb.path('sectorId', params.sectorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfessorSearchResponse>>;
      })
    );
  }

  /**
   * Get all professors according to the sectorId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfessorsBySectorIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorsBySectorIdAsync(params: {
    sectorId: string;
  },
  context?: HttpContext

): Observable<Array<ProfessorSearchResponse>> {

    return this.getProfessorsBySectorIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProfessorSearchResponse>>) => r.body as Array<ProfessorSearchResponse>)
    );
  }

  /**
   * Path part for operation getSupervisorsByLastNameAsync
   */
  static readonly GetSupervisorsByLastNameAsyncPath = '/Professors/search/supervisors/{lastName}';

  /**
   * Get all supervisors (professors with state IsSupervisor = true) with LastName match (used with front autocomplete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupervisorsByLastNameAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupervisorsByLastNameAsync$Plain$Response(params: {
    lastName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SupervisorResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetSupervisorsByLastNameAsyncPath, 'get');
    if (params) {
      rb.path('lastName', params.lastName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SupervisorResponse>>;
      })
    );
  }

  /**
   * Get all supervisors (professors with state IsSupervisor = true) with LastName match (used with front autocomplete).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupervisorsByLastNameAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupervisorsByLastNameAsync$Plain(params: {
    lastName: string;
  },
  context?: HttpContext

): Observable<Array<SupervisorResponse>> {

    return this.getSupervisorsByLastNameAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SupervisorResponse>>) => r.body as Array<SupervisorResponse>)
    );
  }

  /**
   * Get all supervisors (professors with state IsSupervisor = true) with LastName match (used with front autocomplete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupervisorsByLastNameAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupervisorsByLastNameAsync$Response(params: {
    lastName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SupervisorResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetSupervisorsByLastNameAsyncPath, 'get');
    if (params) {
      rb.path('lastName', params.lastName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SupervisorResponse>>;
      })
    );
  }

  /**
   * Get all supervisors (professors with state IsSupervisor = true) with LastName match (used with front autocomplete).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupervisorsByLastNameAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupervisorsByLastNameAsync(params: {
    lastName: string;
  },
  context?: HttpContext

): Observable<Array<SupervisorResponse>> {

    return this.getSupervisorsByLastNameAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SupervisorResponse>>) => r.body as Array<SupervisorResponse>)
    );
  }

  /**
   * Path part for operation getSupervisorsAndCoSupervisorsByLastNameAsync
   */
  static readonly GetSupervisorsAndCoSupervisorsByLastNameAsyncPath = '/Professors/search/supervisorsancosupervisors/{lastName}';

  /**
   * Get all supervisors (professors with state IsSupervisor = true) and cosupervisors (IsCoSupervisor = true)
   * with LastName match (used with front autocomplete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupervisorsAndCoSupervisorsByLastNameAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupervisorsAndCoSupervisorsByLastNameAsync$Plain$Response(params: {
    lastName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SupervisorResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetSupervisorsAndCoSupervisorsByLastNameAsyncPath, 'get');
    if (params) {
      rb.path('lastName', params.lastName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SupervisorResponse>>;
      })
    );
  }

  /**
   * Get all supervisors (professors with state IsSupervisor = true) and cosupervisors (IsCoSupervisor = true)
   * with LastName match (used with front autocomplete).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupervisorsAndCoSupervisorsByLastNameAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupervisorsAndCoSupervisorsByLastNameAsync$Plain(params: {
    lastName: string;
  },
  context?: HttpContext

): Observable<Array<SupervisorResponse>> {

    return this.getSupervisorsAndCoSupervisorsByLastNameAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SupervisorResponse>>) => r.body as Array<SupervisorResponse>)
    );
  }

  /**
   * Get all supervisors (professors with state IsSupervisor = true) and cosupervisors (IsCoSupervisor = true)
   * with LastName match (used with front autocomplete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupervisorsAndCoSupervisorsByLastNameAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupervisorsAndCoSupervisorsByLastNameAsync$Response(params: {
    lastName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SupervisorResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetSupervisorsAndCoSupervisorsByLastNameAsyncPath, 'get');
    if (params) {
      rb.path('lastName', params.lastName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SupervisorResponse>>;
      })
    );
  }

  /**
   * Get all supervisors (professors with state IsSupervisor = true) and cosupervisors (IsCoSupervisor = true)
   * with LastName match (used with front autocomplete).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupervisorsAndCoSupervisorsByLastNameAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupervisorsAndCoSupervisorsByLastNameAsync(params: {
    lastName: string;
  },
  context?: HttpContext

): Observable<Array<SupervisorResponse>> {

    return this.getSupervisorsAndCoSupervisorsByLastNameAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SupervisorResponse>>) => r.body as Array<SupervisorResponse>)
    );
  }

  /**
   * Path part for operation getProfessorInformationsByIdAsync
   */
  static readonly GetProfessorInformationsByIdAsyncPath = '/Professors/informations/{professorId}';

  /**
   * Return informations about a professor for the coordinator view.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfessorInformationsByIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorInformationsByIdAsync$Plain$Response(params: {
    professorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ProfessorThesisInformationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetProfessorInformationsByIdAsyncPath, 'get');
    if (params) {
      rb.path('professorId', params.professorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfessorThesisInformationsResponse>;
      })
    );
  }

  /**
   * Return informations about a professor for the coordinator view.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfessorInformationsByIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorInformationsByIdAsync$Plain(params: {
    professorId: string;
  },
  context?: HttpContext

): Observable<ProfessorThesisInformationsResponse> {

    return this.getProfessorInformationsByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProfessorThesisInformationsResponse>) => r.body as ProfessorThesisInformationsResponse)
    );
  }

  /**
   * Return informations about a professor for the coordinator view.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfessorInformationsByIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorInformationsByIdAsync$Response(params: {
    professorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ProfessorThesisInformationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfessorsService.GetProfessorInformationsByIdAsyncPath, 'get');
    if (params) {
      rb.path('professorId', params.professorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfessorThesisInformationsResponse>;
      })
    );
  }

  /**
   * Return informations about a professor for the coordinator view.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfessorInformationsByIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfessorInformationsByIdAsync(params: {
    professorId: string;
  },
  context?: HttpContext

): Observable<ProfessorThesisInformationsResponse> {

    return this.getProfessorInformationsByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProfessorThesisInformationsResponse>) => r.body as ProfessorThesisInformationsResponse)
    );
  }

}
