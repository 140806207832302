import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';

import { AuthenticationModule } from '@coreServices/authenticationService/authentication.module';
import { MonitoringService } from '@coreServices/monitoringService/monitoring.service';
import StorageService from '@coreServices/globalStorageService';
import { appReducer } from 'src/store/app/app.reducer';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, AuthenticationModule, StoreModule.forRoot(appReducer)],
  providers: [StorageService, MonitoringService],
})
export class CoreModule {}
