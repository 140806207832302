import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from '@sharedComponents/select/select.component';
import { MaterialModule } from './material.module';
import { DialogComponent } from '@sharedComponents/dialog/dialog.component';
import { HeaderComponent } from '@sharedComponents/header/header.component';
import { ProfileMenuComponent } from '@sharedComponents/profile-menu/profile-menu.component';
import { LanguageSwitchComponent } from '@sharedComponents/language-switch/language-switch.component';
import { FilesUploaderComponent } from '@sharedComponents/files-uploader/files-uploader.component';
import { DragAndDropDirective } from './directives/draganddrop.directive';
import { ProfileLoaderComponent } from '@sharedComponents/horizontal-loader/horizontal-loader.component';
import { LoaderComponent } from '@sharedComponents/loader/loader.component';
import { ValidationWarningComponent } from '@sharedComponents/validation-warning/validation-warning.component';
import { DepositFileComponent } from '@sharedComponents/deposit-file/deposit-file.component';
import { ValidationComponent } from '@sharedComponents/validation/validation.component';
import { MessageComponent } from '@sharedComponents/message/message.component';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicFormComponent } from '@sharedComponents/dynamic-form/dynamic-form.component';
import { FooterComponent } from '@sharedComponents/footer/footer.component';
import { StepTitleComponent } from '@sharedComponents/step-title/step-title.component';
import { InformationsPanelComponent } from '@sharedComponents/informations-panel/informations-panel.component';
import { IconButtonComponent } from '@sharedComponents/icon-button/icon-button.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { FinalDocIconComponent } from '@sharedComponents/final-doc-icon/final-doc-icon.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { UnauthorizedComponent } from '@sharedComponents/unauthorized/unauthorized.component';
import { BreadcrumbComponent } from '@sharedComponents/breadcrumb/breadcrumb.component';
import { StepNavigatorComponent } from '@sharedComponents/step-navigator/step-navigator.component';
import { DocumentsTableComponent } from '@sharedComponents/documents-table/documents-table.component';
import { ResearchSpinnerComponent } from '@sharedComponents/research-spinner/research-spinner.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, TranslateModule.forChild()],
  declarations: [
    ResearchSpinnerComponent,
    DocumentsTableComponent,
    StepNavigatorComponent,
    BreadcrumbComponent,
    UnauthorizedComponent,
    FinalDocIconComponent,
    IconButtonComponent,
    InformationsPanelComponent,
    StepTitleComponent,
    FooterComponent,
    DynamicFormComponent,
    ValidationComponent,
    DepositFileComponent,
    ValidationWarningComponent,
    MessageComponent,
    DragAndDropDirective,
    FilesUploaderComponent,
    ProfileLoaderComponent,
    LoaderComponent,
    LanguageSwitchComponent,
    ProfileMenuComponent,
    DialogComponent,
    ButtonComponent,
    SelectComponent,
    HeaderComponent,
    LoaderComponent,
    TimelineComponent,
    NumbersOnlyDirective,
  ],
  exports: [
    ResearchSpinnerComponent,
    DocumentsTableComponent,
    StepNavigatorComponent,
    BreadcrumbComponent,
    FinalDocIconComponent,
    IconButtonComponent,
    InformationsPanelComponent,
    StepTitleComponent,
    DynamicFormComponent,
    FooterComponent,
    ValidationComponent,
    DepositFileComponent,
    ValidationWarningComponent,
    MessageComponent,
    LoaderComponent,
    ProfileLoaderComponent,
    FilesUploaderComponent,
    LanguageSwitchComponent,
    ProfileMenuComponent,
    ButtonComponent,
    SelectComponent,
    HeaderComponent,
    DialogComponent,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    TimelineComponent,
    NumbersOnlyDirective,
  ],
})
export class SharedModule {}
