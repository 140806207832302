import { Action, ActionReducer, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { StudentState } from './student.states';
import { StudentResponse } from '@sharedModels/student-response';
import { StudentActions } from './student.actions';

const initialState: StudentState = {
  currentStudentData: {} as StudentResponse,
};

const _studentReducer: ActionReducer<StudentState, Action> = createReducer(
  // Supply the initial state
  initialState,
  on(StudentActions.setStudentData, (state: StudentState, data: StudentResponse) => ({
    ...state,
    currentStudentData: { ...data },
  })),
  on(StudentActions.getStudentData, (state: StudentState) => ({
    ...state,
    currentStudentData: {} as StudentResponse,
  })),
  on(StudentActions.updateStudentData, (state: StudentState, { updates }) => ({
    ...state,
    currentStudentData: {
      ...state.currentStudentData,
      ...updates,
    },
  }))
);

export function studentReducer(state: StudentState, action: Action) {
  return _studentReducer(state, action);
}

// Selectors
export const getStudentState = createFeatureSelector<StudentState>('studentState');
export const getStudent = createSelector(getStudentState, (state: StudentState) => state.currentStudentData);
