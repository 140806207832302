import { createAction, props } from '@ngrx/store';
import { ThesisTimelineState } from './thesis-timeline.states';

export const ThesisTimelineActionsTypes = {
  GET_THESIS_TIMELINE: 'GET_THESIS_TIMELINE',
  UPDATE_THESIS_TIMELINE: 'UPDATE_THESIS_TIMELINE',
};

const getThesisTimeline = createAction(ThesisTimelineActionsTypes.GET_THESIS_TIMELINE);
const updateThesisTimeline = createAction(ThesisTimelineActionsTypes.UPDATE_THESIS_TIMELINE, props<Partial<ThesisTimelineState>>());

export const ThesisTimelineActions = {
  getThesisTimeline,
  updateThesisTimeline,
};
