import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() name!: string;
  @Input() disabled = false;
  @Input() stroked = true;
  @Input() isValid = false;
  @Input() iconName!: string;
  @Input() customTooltip!: string;
  @Input() tooltipContent!: string;
  @Input() tooltipPosition: TooltipPosition = 'above';
  @Input() type = 'button';
  @Output() readonly clickCallbackFn = new EventEmitter<void>();

  emitClick() {
    this.clickCallbackFn.emit();
  }
}
