/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AnswersService } from './services/answers.service';
import { ArbitrationsService } from './services/arbitrations.service';
import { CampusService } from './services/campus.service';
import { CommentsService } from './services/comments.service';
import { ConventionsService } from './services/conventions.service';
import { CoordinatorCommentsService } from './services/coordinator-comments.service';
import { CoordinatorsService } from './services/coordinators.service';
import { CountriesService } from './services/countries.service';
import { DocumentsService } from './services/documents.service';
import { ExaminationAnswersService } from './services/examination-answers.service';
import { ExaminationQuestionCategoriesService } from './services/examination-question-categories.service';
import { ExaminationQuestionsService } from './services/examination-questions.service';
import { ExaminationsService } from './services/examinations.service';
import { HistoricsService } from './services/historics.service';
import { ImportAurionService } from './services/import-aurion.service';
import { ProfessorsService } from './services/professors.service';
import { ProjectsService } from './services/projects.service';
import { QuestionCategoriesService } from './services/question-categories.service';
import { QuestionsService } from './services/questions.service';
import { SectorsService } from './services/sectors.service';
import { SemestersService } from './services/semesters.service';
import { SpecialisationsService } from './services/specialisations.service';
import { StepsService } from './services/steps.service';
import { StepTypesService } from './services/step-types.service';
import { StudentsService } from './services/students.service';
import { SupervisionsService } from './services/supervisions.service';
import { SupervisorsService } from './services/supervisors.service';
import { TestModesService } from './services/test-modes.service';
import { ThesisService } from './services/thesis.service';
import { UsersService } from './services/users.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AnswersService,
    ArbitrationsService,
    CampusService,
    CommentsService,
    ConventionsService,
    CoordinatorCommentsService,
    CoordinatorsService,
    CountriesService,
    DocumentsService,
    ExaminationAnswersService,
    ExaminationQuestionCategoriesService,
    ExaminationQuestionsService,
    ExaminationsService,
    HistoricsService,
    ImportAurionService,
    ProfessorsService,
    ProjectsService,
    QuestionCategoriesService,
    QuestionsService,
    SectorsService,
    SemestersService,
    SpecialisationsService,
    StepsService,
    StepTypesService,
    StudentsService,
    SupervisionsService,
    SupervisorsService,
    TestModesService,
    ThesisService,
    UsersService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
