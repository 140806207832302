<div>
  <div class="profile-container" *ngIf="!loading">
    <div class="user-icon">
      <div class="icon">
        {{ initials }}
      </div>
    </div>
    <div>{{ fullName }}</div>
    <div class="disconnect-btn" matTooltip="Disconnect" (click)="logout()">
      <img src="/assets/img/logout.svg" />
    </div>
  </div>
  <app-horizontal-loader [loading]="loading"></app-horizontal-loader>
  <span *ngIf="!userExist && !loading" class="disconnected">Vous êtes déconnectés</span>
</div>
