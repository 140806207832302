import { UserState } from 'src/store/user/user.states';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Roles } from '@sharedEnums/roles.enum';
import { UserResponse } from '@sharedModels/user-response';
import { map } from 'rxjs';
import * as userReducer from 'src/store/user/user.reducer';

export const SUPERVISOR_ROLES = [Roles.Supervisor, Roles.CoSupervisor, Roles.SectorDirector];
export const COORDINATOR_ROLES = [Roles.Coordinator, Roles.CoordinatorAdmin];

/**
 * Checks if the user has the necessary roles to access a specific route.
 * * This method satisfies the `CanActivateFn` type from Angular's router.
 * * See https://angular.io/api/router/CanActivateFn
 *
 * @return An observable that emits true if the user has the necessary roles, false otherwise.
 */
export function RolesGuard(route: ActivatedRouteSnapshot, _2: RouterStateSnapshot, userStore = inject(Store<UserState>), router = inject(Router)) {
  return userStore.select(userReducer.getUser).pipe(
    map((user: UserResponse) => {
      if (!user || !user.id) {
        return false;
      }

      // Use the route config path to determine where user wants to navigate
      const goingToStudentPages = route.routeConfig?.path === 'student';
      const goingToSupervisorPages = route.routeConfig?.path === 'supervisor';
      const goingToCoordinatorPages = route.routeConfig?.path === 'coordinator';

      const userRoles = user.roles?.map(a => a.userRole) ?? [];
      const canActivateStudentPages = canActivateStudent(userRoles);
      const canActivateSupervisorPages = canActivateSupervisor(userRoles);
      const canActivateCoordinatorPages = canActivateCoordinator(userRoles);

      if (canActivateStudentPages && !goingToStudentPages) {
        return router.parseUrl('/student');
      } else if (canActivateSupervisorPages && !goingToSupervisorPages) {
        return router.parseUrl('/supervisor');
      } else if (canActivateCoordinatorPages && !goingToCoordinatorPages) {
        return router.parseUrl('/coordinator');
      } else if (!canActivateCoordinatorPages && !canActivateStudentPages && !canActivateSupervisorPages) {
        return router.parseUrl('/unauthorized');
      }

      return true;
    })
  );
}

/**
 * Determines if a user can activate as a student.
 *
 * @param userRoles - The roles of the user.
 * @return A boolean value indicating if the user can activate as a student.
 */
function canActivateStudent(userRoles: (string | null | undefined)[]) {
  const studentRole = userRoles.find(role => role === Roles.Student);
  if (studentRole && userRoles.length == 1) {
    return true;
  }

  return false;
}

/**
 * Determines if a user can activate as a supervisor.
 *
 * @param userRoles - The roles of the user.
 * @return A boolean value indicating if the user can activate as a supervisor.
 */
function canActivateSupervisor(userRoles: (string | null | undefined)[]) {
  if (userRoles.includes(Roles.Student)) {
    return false;
  }

  if (userRoles.some(role => SUPERVISOR_ROLES.includes(role as Roles))) {
    return true;
  }

  return false;
}

/**
 * Determines if a user can activate as a coordinator.
 *
 * @param userRoles - The roles of the user.
 * @return A boolean value indicating if the user can activate as a coordinator.
 */
function canActivateCoordinator(userRoles: (string | null | undefined)[]) {
  if (userRoles.some(role => COORDINATOR_ROLES.includes(role as Roles))) {
    return true;
  }
  return false;
}
