import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Roles } from '@sharedEnums/roles.enum';
import { Specialisation } from '@sharedEnums/specialisation.enum';
import { Subscription } from 'rxjs';
import { getStudent } from 'src/store/student/student.reducer';
import { StudentState } from 'src/store/student/student.states';
import { getUser } from 'src/store/user/user.reducer';
import { UserState } from 'src/store/user/user.states';

export const COORDINATOR_ROLES = [Roles.Coordinator, Roles.CoordinatorAdmin];
export const SUPERVISOR_ROLES = [Roles.Supervisor, Roles.CoSupervisor, Roles.SectorDirector];
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private router: Router, private userStore: Store<UserState>, private studentStore: Store<StudentState>) {}
  headerSubscription = new Subscription();
  studentSpecialisation!: string;
  specialisationEnum = Specialisation;
  coordinatorMenu = ['HOME', 'PROFESSORS', 'STUDENTS', 'SEMESTER'];
  supervisorMode!: boolean;
  coordinatorMode!: boolean;
  studentMode!: boolean;

  ngOnInit(): void {
    this.headerSubscription.add(
      this.userStore.select(getUser).subscribe(user => {
        if (user && user.roles) {
          this.coordinatorMode = user.roles.map(a => a.userRole).some(role => COORDINATOR_ROLES.includes(role as Roles));
          this.supervisorMode = user.roles.map(a => a.userRole).some(role => SUPERVISOR_ROLES.includes(role as Roles));
          this.studentMode = user.roles.map(a => a.userRole).some(role => role == Roles.Student);
        }
        if (this.studentMode) {
          this.studentStore.select(getStudent).subscribe(result => {
            if (result.specialisation) this.studentSpecialisation = result.specialisation.name;
          });
        }
      })
    );
  }

  gotoPage(item: string) {
    this.router.navigate(['coordinator', item.toLowerCase()]);
  }

  goHome() {
    if (this.coordinatorMode) {
      this.router.navigate(['coordinator']);
      return;
    }
    if (this.supervisorMode) {
      this.router.navigate(['supervisor']);
      return;
    }
    this.router.navigate(['student']);
  }
}
