import { Action, ActionReducer, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ThesisTimelineState } from './thesis-timeline.states';
import { ThesisTimelineActions } from './thesis-timeline.actions';

const initialState: ThesisTimelineState = {
  activeStep: 0,
  steps: [],
};

const _thesisTimelineReducer: ActionReducer<ThesisTimelineState, Action> = createReducer(
  initialState,
  on(ThesisTimelineActions.getThesisTimeline, (state: ThesisTimelineState) => ({
    ...state,
  })),
  on(ThesisTimelineActions.updateThesisTimeline, (state: ThesisTimelineState, updates) => ({
    ...state,
    ...updates,
  }))
);

export function thesisTimelineReducer(state: ThesisTimelineState, action: Action) {
  return _thesisTimelineReducer(state, action);
}

// Selectors
const getThesisTimelineState = createFeatureSelector<ThesisTimelineState>('thesisTimelineState');
export const getThesisTimeline = createSelector(getThesisTimelineState, (state: ThesisTimelineState) => state);
