/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateExaminationRequest } from '../models/create-or-update-examination-request';
import { ExaminationResponse } from '../models/examination-response';

@Injectable({
  providedIn: 'root',
})
export class ExaminationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createExaminationAsync
   */
  static readonly CreateExaminationAsyncPath = '/Examinations';

  /**
   * Create examination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExaminationAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExaminationAsync$Plain$Response(params?: {
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ExaminationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationsService.CreateExaminationAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationResponse>;
      })
    );
  }

  /**
   * Create examination.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExaminationAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExaminationAsync$Plain(params?: {
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<ExaminationResponse> {

    return this.createExaminationAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ExaminationResponse>) => r.body as ExaminationResponse)
    );
  }

  /**
   * Create examination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExaminationAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExaminationAsync$Response(params?: {
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ExaminationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationsService.CreateExaminationAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationResponse>;
      })
    );
  }

  /**
   * Create examination.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExaminationAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExaminationAsync(params?: {
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<ExaminationResponse> {

    return this.createExaminationAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ExaminationResponse>) => r.body as ExaminationResponse)
    );
  }

  /**
   * Path part for operation updateExaminationByIdAsync
   */
  static readonly UpdateExaminationByIdAsyncPath = '/Examinations/{examinationId}';

  /**
   * Update examination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExaminationByIdAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExaminationByIdAsync$Plain$Response(params: {
    examinationId: string;
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ExaminationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationsService.UpdateExaminationByIdAsyncPath, 'put');
    if (params) {
      rb.path('examinationId', params.examinationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationResponse>;
      })
    );
  }

  /**
   * Update examination.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExaminationByIdAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExaminationByIdAsync$Plain(params: {
    examinationId: string;
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<ExaminationResponse> {

    return this.updateExaminationByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ExaminationResponse>) => r.body as ExaminationResponse)
    );
  }

  /**
   * Update examination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExaminationByIdAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExaminationByIdAsync$Response(params: {
    examinationId: string;
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ExaminationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationsService.UpdateExaminationByIdAsyncPath, 'put');
    if (params) {
      rb.path('examinationId', params.examinationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationResponse>;
      })
    );
  }

  /**
   * Update examination.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExaminationByIdAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExaminationByIdAsync(params: {
    examinationId: string;
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<ExaminationResponse> {

    return this.updateExaminationByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ExaminationResponse>) => r.body as ExaminationResponse)
    );
  }

}
