import { createAction, props } from '@ngrx/store';
import { Roles } from '@sharedEnums/roles.enum';
import { SupervisorResponse } from '@sharedModels/supervisor-response';

export const SupervisorActionsTypes = {
  GET_SUPERVISOR: 'GET_SUPERVISOR',
  SET_SUPERVISOR: 'SET_SUPERVISOR',
  SET_THESIS_ROLE: 'SET_THESIS_ROLE',
};

const setThesisRole = createAction(SupervisorActionsTypes.SET_THESIS_ROLE, props<{ thesisRole: Roles }>());
const setSupervisor = createAction(SupervisorActionsTypes.SET_SUPERVISOR, props<SupervisorResponse>());
const getSupervisor = createAction(SupervisorActionsTypes.GET_SUPERVISOR);

export const SupervisorActions = {
  setSupervisor,
  getSupervisor,
  setThesisRole,
};
