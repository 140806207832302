/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class TestModesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation testModeStudentSwitchSpecialisationAsync
   */
  static readonly TestModeStudentSwitchSpecialisationAsyncPath = '/TestModes';

  /**
   * ENABLED INTEGRATION/DEBUG ONLY (use appsettings.json to enable or not this feature)
   * Allow user to clean data and switch profile (EDI, CLASSIQUE, ALTERNANCE).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testModeStudentSwitchSpecialisationAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  testModeStudentSwitchSpecialisationAsync$Plain$Response(params?: {
    studentId?: string;
    thesisId?: string;
    specialisationCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TestModesService.TestModeStudentSwitchSpecialisationAsyncPath, 'post');
    if (params) {
      rb.query('studentId', params.studentId, {});
      rb.query('thesisId', params.thesisId, {});
      rb.query('specialisationCode', params.specialisationCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * ENABLED INTEGRATION/DEBUG ONLY (use appsettings.json to enable or not this feature)
   * Allow user to clean data and switch profile (EDI, CLASSIQUE, ALTERNANCE).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testModeStudentSwitchSpecialisationAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  testModeStudentSwitchSpecialisationAsync$Plain(params?: {
    studentId?: string;
    thesisId?: string;
    specialisationCode?: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.testModeStudentSwitchSpecialisationAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * ENABLED INTEGRATION/DEBUG ONLY (use appsettings.json to enable or not this feature)
   * Allow user to clean data and switch profile (EDI, CLASSIQUE, ALTERNANCE).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testModeStudentSwitchSpecialisationAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  testModeStudentSwitchSpecialisationAsync$Response(params?: {
    studentId?: string;
    thesisId?: string;
    specialisationCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TestModesService.TestModeStudentSwitchSpecialisationAsyncPath, 'post');
    if (params) {
      rb.query('studentId', params.studentId, {});
      rb.query('thesisId', params.thesisId, {});
      rb.query('specialisationCode', params.specialisationCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * ENABLED INTEGRATION/DEBUG ONLY (use appsettings.json to enable or not this feature)
   * Allow user to clean data and switch profile (EDI, CLASSIQUE, ALTERNANCE).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testModeStudentSwitchSpecialisationAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  testModeStudentSwitchSpecialisationAsync(params?: {
    studentId?: string;
    thesisId?: string;
    specialisationCode?: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.testModeStudentSwitchSpecialisationAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation testModeDeleteThesisByIdAsync
   */
  static readonly TestModeDeleteThesisByIdAsyncPath = '/TestModes';

  /**
   * ENABLED INTEGRATION/DEBUG ONLY (use appsettings.json to enable or not this feature)
   * Delete thesis and all data attached.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testModeDeleteThesisByIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  testModeDeleteThesisByIdAsync$Plain$Response(params?: {
    thesisId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TestModesService.TestModeDeleteThesisByIdAsyncPath, 'delete');
    if (params) {
      rb.query('thesisId', params.thesisId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * ENABLED INTEGRATION/DEBUG ONLY (use appsettings.json to enable or not this feature)
   * Delete thesis and all data attached.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testModeDeleteThesisByIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  testModeDeleteThesisByIdAsync$Plain(params?: {
    thesisId?: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.testModeDeleteThesisByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * ENABLED INTEGRATION/DEBUG ONLY (use appsettings.json to enable or not this feature)
   * Delete thesis and all data attached.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testModeDeleteThesisByIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  testModeDeleteThesisByIdAsync$Response(params?: {
    thesisId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TestModesService.TestModeDeleteThesisByIdAsyncPath, 'delete');
    if (params) {
      rb.query('thesisId', params.thesisId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * ENABLED INTEGRATION/DEBUG ONLY (use appsettings.json to enable or not this feature)
   * Delete thesis and all data attached.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testModeDeleteThesisByIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  testModeDeleteThesisByIdAsync(params?: {
    thesisId?: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.testModeDeleteThesisByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
