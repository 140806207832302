import { Action, ActionReducer, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { CoordinatorState } from './coordinator.states';
import { CoordinatorResponse } from '@sharedModels/coordinator-response';
import { CoordinatorActions } from './coordinator.actions';

const initialState: CoordinatorState = {
  currentCoordinator: null,
};

const _coordinatorReducer: ActionReducer<CoordinatorState, Action> = createReducer(
  initialState,
  on(CoordinatorActions.setCoordinator, (state: CoordinatorState, data: CoordinatorResponse) => ({
    ...state,
    currentCoordinator: { ...data },
  })),
  on(CoordinatorActions.getCoordinator, (state: CoordinatorState) => ({
    ...state,
  }))
);

export function coordinatorReducer(state: CoordinatorState, action: Action) {
  return _coordinatorReducer(state, action);
}

export const getCoordinatorState = createFeatureSelector<CoordinatorState>('coordinatorState');
export const getCoordinator = createSelector(getCoordinatorState, (state: CoordinatorState) => state.currentCoordinator);
