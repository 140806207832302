/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConventionResponse } from '../models/convention-response';
import { CreateOrUpdateConventionRequest } from '../models/create-or-update-convention-request';

@Injectable({
  providedIn: 'root',
})
export class ConventionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getConventionByIdAsync
   */
  static readonly GetConventionByIdAsyncPath = '/Conventions';

  /**
   * Get convention by conventionId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConventionByIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConventionByIdAsync$Plain$Response(params?: {
    conventionId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConventionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ConventionsService.GetConventionByIdAsyncPath, 'get');
    if (params) {
      rb.query('conventionId', params.conventionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConventionResponse>;
      })
    );
  }

  /**
   * Get convention by conventionId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConventionByIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConventionByIdAsync$Plain(params?: {
    conventionId?: string;
  },
  context?: HttpContext

): Observable<ConventionResponse> {

    return this.getConventionByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConventionResponse>) => r.body as ConventionResponse)
    );
  }

  /**
   * Get convention by conventionId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConventionByIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConventionByIdAsync$Response(params?: {
    conventionId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConventionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ConventionsService.GetConventionByIdAsyncPath, 'get');
    if (params) {
      rb.query('conventionId', params.conventionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConventionResponse>;
      })
    );
  }

  /**
   * Get convention by conventionId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConventionByIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConventionByIdAsync(params?: {
    conventionId?: string;
  },
  context?: HttpContext

): Observable<ConventionResponse> {

    return this.getConventionByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConventionResponse>) => r.body as ConventionResponse)
    );
  }

  /**
   * Path part for operation createConventionAsync
   */
  static readonly CreateConventionAsyncPath = '/Conventions';

  /**
   * Create convention.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createConventionAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createConventionAsync$Plain$Response(params?: {
    body?: CreateOrUpdateConventionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConventionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ConventionsService.CreateConventionAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConventionResponse>;
      })
    );
  }

  /**
   * Create convention.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createConventionAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createConventionAsync$Plain(params?: {
    body?: CreateOrUpdateConventionRequest
  },
  context?: HttpContext

): Observable<ConventionResponse> {

    return this.createConventionAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConventionResponse>) => r.body as ConventionResponse)
    );
  }

  /**
   * Create convention.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createConventionAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createConventionAsync$Response(params?: {
    body?: CreateOrUpdateConventionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConventionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ConventionsService.CreateConventionAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConventionResponse>;
      })
    );
  }

  /**
   * Create convention.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createConventionAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createConventionAsync(params?: {
    body?: CreateOrUpdateConventionRequest
  },
  context?: HttpContext

): Observable<ConventionResponse> {

    return this.createConventionAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConventionResponse>) => r.body as ConventionResponse)
    );
  }

  /**
   * Path part for operation updateConventionByIdAsync
   */
  static readonly UpdateConventionByIdAsyncPath = '/Conventions/{conventionId}';

  /**
   * Update convention by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateConventionByIdAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateConventionByIdAsync$Plain$Response(params: {
    conventionId: string;
    body?: CreateOrUpdateConventionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConventionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ConventionsService.UpdateConventionByIdAsyncPath, 'put');
    if (params) {
      rb.path('conventionId', params.conventionId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConventionResponse>;
      })
    );
  }

  /**
   * Update convention by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateConventionByIdAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateConventionByIdAsync$Plain(params: {
    conventionId: string;
    body?: CreateOrUpdateConventionRequest
  },
  context?: HttpContext

): Observable<ConventionResponse> {

    return this.updateConventionByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConventionResponse>) => r.body as ConventionResponse)
    );
  }

  /**
   * Update convention by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateConventionByIdAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateConventionByIdAsync$Response(params: {
    conventionId: string;
    body?: CreateOrUpdateConventionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConventionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ConventionsService.UpdateConventionByIdAsyncPath, 'put');
    if (params) {
      rb.path('conventionId', params.conventionId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConventionResponse>;
      })
    );
  }

  /**
   * Update convention by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateConventionByIdAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateConventionByIdAsync(params: {
    conventionId: string;
    body?: CreateOrUpdateConventionRequest
  },
  context?: HttpContext

): Observable<ConventionResponse> {

    return this.updateConventionByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConventionResponse>) => r.body as ConventionResponse)
    );
  }

  /**
   * Path part for operation deleteConventionByIdAsync
   */
  static readonly DeleteConventionByIdAsyncPath = '/Conventions/{conventionId}';

  /**
   * Delete convention by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConventionByIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConventionByIdAsync$Plain$Response(params: {
    conventionId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ConventionsService.DeleteConventionByIdAsyncPath, 'delete');
    if (params) {
      rb.path('conventionId', params.conventionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete convention by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConventionByIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConventionByIdAsync$Plain(params: {
    conventionId: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.deleteConventionByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete convention by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConventionByIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConventionByIdAsync$Response(params: {
    conventionId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ConventionsService.DeleteConventionByIdAsyncPath, 'delete');
    if (params) {
      rb.path('conventionId', params.conventionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete convention by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConventionByIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConventionByIdAsync(params: {
    conventionId: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.deleteConventionByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
