import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StudentState } from 'src/store/student/student.states';
import { DocumentResponse } from '@sharedModels/document-response';
import { StepResponse } from '@sharedModels/step-response';
import { ConventionResponse } from '@sharedModels/convention-response';
import { StudentActions } from 'src/store/student/student.actions';
import { StudentResponse } from '@sharedModels/student-response';
import { ThesisResponse } from '@sharedModels/thesis-response';

@Injectable()
export class GlobalStorageService {
  constructor(private store: Store<StudentState>) {}

  public getGridFilters() {
    const currentFilters = localStorage.getItem('filters');
    if (currentFilters) return JSON.parse(currentFilters);
  }

  public setGridFilters(formValues: any) {
    localStorage.setItem('filters', JSON.stringify(formValues));
  }

  public getGridColumns(): string[] {
    const grid = localStorage.getItem('columns');

    if (grid && grid !== '') {
      return JSON.parse(grid);
    }
    return [];
  }

  public setGridColums(columns: string[]) {
    localStorage.setItem('columns', JSON.stringify(columns));
  }

  public setToken(token: string) {
    localStorage.setItem('token', token);
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public setUser(user: StudentResponse) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public getUser(): StudentResponse | null {
    const userStorage = localStorage.getItem('user');
    if (userStorage != null) {
      return JSON.parse(userStorage) as StudentResponse;
    }
    return null;
  }

  public updateStudentCurrentStepAndDispatchToStore(student: StudentResponse, step: StepResponse) {
    const updates: Partial<StudentResponse> = {
      ...student,
      currentStep: step.stepNumber,
      currentStepId: step.id,
      currentStepStatus: step.status,
    };
    this.store.dispatch(StudentActions.updateStudentData({ updates }));
  }

  public updateThesisDocumentsAndDispatchToStore(currentStudent: StudentResponse, updatedDocuments: DocumentResponse[]) {
    const updates: Partial<StudentResponse> = {
      ...currentStudent,
      thesis: {
        ...currentStudent.thesis!,
        documents: updatedDocuments,
      },
    };
    this.store.dispatch(StudentActions.updateStudentData({ updates }));
  }

  public updateConventionAndDispatchToStore(currentStudent: StudentResponse, convention: ConventionResponse | undefined) {
    const updates: Partial<StudentResponse> = {
      ...currentStudent,
      thesis: {
        ...currentStudent.thesis!,
        convention: convention,
      },
    };
    this.store.dispatch(StudentActions.updateStudentData({ updates }));
  }

  public updateThesisAndDispatchToStore(currentStudent: StudentResponse, thesis: ThesisResponse | undefined) {
    const updates: Partial<StudentResponse> = {
      ...currentStudent,
      thesis: {
        ...thesis!,
      },
    };
    this.store.dispatch(StudentActions.updateStudentData({ updates }));
  }
}
