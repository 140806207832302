import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-horizontal-loader',
  templateUrl: './horizontal-loader.component.html',
  styleUrls: ['./horizontal-loader.component.scss'],
})
export class ProfileLoaderComponent {
  @Input() loading = false;
}
