<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <img src="/assets/img/picto-warning.svg" />
    </div>
    <mat-card-title>{{ 'ERROR.UNAUTHORIZED' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>{{ 'ERROR.UNAUTHORIZED_MESSAGE' | translate }}</p>
  </mat-card-content>
</mat-card>
