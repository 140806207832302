/** * Decorator to place on a Component to automatically free its subscriptions upon component destruction * It prevents huge memory leak, performance issues and unexpected behaviors of the application */
export function AutoUnsubscribe() {
  return (constructor: any) => {
    // Saving the original `ngOnDestroy` method
    const originalOnDestroy = constructor.prototype.ngOnDestroy;
    constructor.prototype.ngOnDestroy = function () {
      for (const prop in this) {
        if (Object.hasOwnProperty.call(this, prop)) {
          const property = this[prop];
          if (property && typeof property.unsubscribe === 'function') {
            property.unsubscribe();
          }
        }
      }
      // Running the originalOnDestroy `ngOnDestroy` method
      if (originalOnDestroy) {
        originalOnDestroy.apply(this);
      }
    };
  };
}
