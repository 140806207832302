/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CoordinatorCommentResponse } from '../models/coordinator-comment-response';
import { CreateOrUpdateCoordinatorCommentRequest } from '../models/create-or-update-coordinator-comment-request';

@Injectable({
  providedIn: 'root',
})
export class CoordinatorCommentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createCoordinatorCommentAsync
   */
  static readonly CreateCoordinatorCommentAsyncPath = '/CoordinatorComments';

  /**
   * Create coordinator comment on thesis.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCoordinatorCommentAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCoordinatorCommentAsync$Plain$Response(params?: {
    body?: CreateOrUpdateCoordinatorCommentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CoordinatorCommentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CoordinatorCommentsService.CreateCoordinatorCommentAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CoordinatorCommentResponse>;
      })
    );
  }

  /**
   * Create coordinator comment on thesis.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCoordinatorCommentAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCoordinatorCommentAsync$Plain(params?: {
    body?: CreateOrUpdateCoordinatorCommentRequest
  },
  context?: HttpContext

): Observable<CoordinatorCommentResponse> {

    return this.createCoordinatorCommentAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CoordinatorCommentResponse>) => r.body as CoordinatorCommentResponse)
    );
  }

  /**
   * Create coordinator comment on thesis.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCoordinatorCommentAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCoordinatorCommentAsync$Response(params?: {
    body?: CreateOrUpdateCoordinatorCommentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CoordinatorCommentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CoordinatorCommentsService.CreateCoordinatorCommentAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CoordinatorCommentResponse>;
      })
    );
  }

  /**
   * Create coordinator comment on thesis.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCoordinatorCommentAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCoordinatorCommentAsync(params?: {
    body?: CreateOrUpdateCoordinatorCommentRequest
  },
  context?: HttpContext

): Observable<CoordinatorCommentResponse> {

    return this.createCoordinatorCommentAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<CoordinatorCommentResponse>) => r.body as CoordinatorCommentResponse)
    );
  }

  /**
   * Path part for operation updateCoordinatorCommentAsync
   */
  static readonly UpdateCoordinatorCommentAsyncPath = '/CoordinatorComments/{coordinatorCommentId}';

  /**
   * Update a coordinator comment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCoordinatorCommentAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCoordinatorCommentAsync$Plain$Response(params: {
    coordinatorCommentId: string;
    body?: CreateOrUpdateCoordinatorCommentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CoordinatorCommentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CoordinatorCommentsService.UpdateCoordinatorCommentAsyncPath, 'put');
    if (params) {
      rb.path('coordinatorCommentId', params.coordinatorCommentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CoordinatorCommentResponse>;
      })
    );
  }

  /**
   * Update a coordinator comment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCoordinatorCommentAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCoordinatorCommentAsync$Plain(params: {
    coordinatorCommentId: string;
    body?: CreateOrUpdateCoordinatorCommentRequest
  },
  context?: HttpContext

): Observable<CoordinatorCommentResponse> {

    return this.updateCoordinatorCommentAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CoordinatorCommentResponse>) => r.body as CoordinatorCommentResponse)
    );
  }

  /**
   * Update a coordinator comment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCoordinatorCommentAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCoordinatorCommentAsync$Response(params: {
    coordinatorCommentId: string;
    body?: CreateOrUpdateCoordinatorCommentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CoordinatorCommentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CoordinatorCommentsService.UpdateCoordinatorCommentAsyncPath, 'put');
    if (params) {
      rb.path('coordinatorCommentId', params.coordinatorCommentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CoordinatorCommentResponse>;
      })
    );
  }

  /**
   * Update a coordinator comment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCoordinatorCommentAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCoordinatorCommentAsync(params: {
    coordinatorCommentId: string;
    body?: CreateOrUpdateCoordinatorCommentRequest
  },
  context?: HttpContext

): Observable<CoordinatorCommentResponse> {

    return this.updateCoordinatorCommentAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<CoordinatorCommentResponse>) => r.body as CoordinatorCommentResponse)
    );
  }

  /**
   * Path part for operation getCoordinatorCommentsByThesisIdAsync
   */
  static readonly GetCoordinatorCommentsByThesisIdAsyncPath = '/CoordinatorComments/GetCoordinatorCommentsByThesisIdAsync';

  /**
   * Get all the cordinator comments from a thesis.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoordinatorCommentsByThesisIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoordinatorCommentsByThesisIdAsync$Plain$Response(params?: {
    thesisId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CoordinatorCommentResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CoordinatorCommentsService.GetCoordinatorCommentsByThesisIdAsyncPath, 'get');
    if (params) {
      rb.query('thesisId', params.thesisId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CoordinatorCommentResponse>>;
      })
    );
  }

  /**
   * Get all the cordinator comments from a thesis.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCoordinatorCommentsByThesisIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoordinatorCommentsByThesisIdAsync$Plain(params?: {
    thesisId?: string;
  },
  context?: HttpContext

): Observable<Array<CoordinatorCommentResponse>> {

    return this.getCoordinatorCommentsByThesisIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CoordinatorCommentResponse>>) => r.body as Array<CoordinatorCommentResponse>)
    );
  }

  /**
   * Get all the cordinator comments from a thesis.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoordinatorCommentsByThesisIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoordinatorCommentsByThesisIdAsync$Response(params?: {
    thesisId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CoordinatorCommentResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CoordinatorCommentsService.GetCoordinatorCommentsByThesisIdAsyncPath, 'get');
    if (params) {
      rb.query('thesisId', params.thesisId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CoordinatorCommentResponse>>;
      })
    );
  }

  /**
   * Get all the cordinator comments from a thesis.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCoordinatorCommentsByThesisIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoordinatorCommentsByThesisIdAsync(params?: {
    thesisId?: string;
  },
  context?: HttpContext

): Observable<Array<CoordinatorCommentResponse>> {

    return this.getCoordinatorCommentsByThesisIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CoordinatorCommentResponse>>) => r.body as Array<CoordinatorCommentResponse>)
    );
  }

  /**
   * Path part for operation deleteCoordinatorCommentAsync
   */
  static readonly DeleteCoordinatorCommentAsyncPath = '/CoordinatorComments/{idComment}';

  /**
   * Delete a coordinator comment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCoordinatorCommentAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCoordinatorCommentAsync$Plain$Response(params: {
    idComment: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CoordinatorCommentsService.DeleteCoordinatorCommentAsyncPath, 'delete');
    if (params) {
      rb.path('idComment', params.idComment, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a coordinator comment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCoordinatorCommentAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCoordinatorCommentAsync$Plain(params: {
    idComment: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.deleteCoordinatorCommentAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete a coordinator comment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCoordinatorCommentAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCoordinatorCommentAsync$Response(params: {
    idComment: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CoordinatorCommentsService.DeleteCoordinatorCommentAsyncPath, 'delete');
    if (params) {
      rb.path('idComment', params.idComment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a coordinator comment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCoordinatorCommentAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCoordinatorCommentAsync(params: {
    idComment: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.deleteCoordinatorCommentAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
