/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AnswerResponse } from '../models/answer-response';
import { CreateOrUpdateAnswerRequest } from '../models/create-or-update-answer-request';

@Injectable({
  providedIn: 'root',
})
export class AnswersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createAnswerAsync
   */
  static readonly CreateAnswerAsyncPath = '/Answers';

  /**
   * Create answer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAnswerAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAnswerAsync$Plain$Response(params?: {
    body?: CreateOrUpdateAnswerRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnswerResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnswersService.CreateAnswerAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnswerResponse>;
      })
    );
  }

  /**
   * Create answer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAnswerAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAnswerAsync$Plain(params?: {
    body?: CreateOrUpdateAnswerRequest
  },
  context?: HttpContext

): Observable<AnswerResponse> {

    return this.createAnswerAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnswerResponse>) => r.body as AnswerResponse)
    );
  }

  /**
   * Create answer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAnswerAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAnswerAsync$Response(params?: {
    body?: CreateOrUpdateAnswerRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnswerResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnswersService.CreateAnswerAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnswerResponse>;
      })
    );
  }

  /**
   * Create answer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAnswerAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAnswerAsync(params?: {
    body?: CreateOrUpdateAnswerRequest
  },
  context?: HttpContext

): Observable<AnswerResponse> {

    return this.createAnswerAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnswerResponse>) => r.body as AnswerResponse)
    );
  }

  /**
   * Path part for operation updateAnswerAsync
   */
  static readonly UpdateAnswerAsyncPath = '/Answers/{answerId}';

  /**
   * Update answer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnswerAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAnswerAsync$Plain$Response(params: {
    answerId: string;
    body?: CreateOrUpdateAnswerRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnswerResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnswersService.UpdateAnswerAsyncPath, 'put');
    if (params) {
      rb.path('answerId', params.answerId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnswerResponse>;
      })
    );
  }

  /**
   * Update answer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnswerAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAnswerAsync$Plain(params: {
    answerId: string;
    body?: CreateOrUpdateAnswerRequest
  },
  context?: HttpContext

): Observable<AnswerResponse> {

    return this.updateAnswerAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnswerResponse>) => r.body as AnswerResponse)
    );
  }

  /**
   * Update answer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnswerAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAnswerAsync$Response(params: {
    answerId: string;
    body?: CreateOrUpdateAnswerRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnswerResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnswersService.UpdateAnswerAsyncPath, 'put');
    if (params) {
      rb.path('answerId', params.answerId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnswerResponse>;
      })
    );
  }

  /**
   * Update answer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnswerAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAnswerAsync(params: {
    answerId: string;
    body?: CreateOrUpdateAnswerRequest
  },
  context?: HttpContext

): Observable<AnswerResponse> {

    return this.updateAnswerAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnswerResponse>) => r.body as AnswerResponse)
    );
  }

}
