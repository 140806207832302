import { Component, OnInit } from '@angular/core';
import { frenchIcon, ukIcon } from './languages-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {
  frIcon!: string;
  ukIcon!: string;
  defaultIsSelected = true;

  constructor(private translate: TranslateService) {}
  ngOnInit(): void {
    this.frIcon = frenchIcon.selectedValue;
    this.ukIcon = ukIcon.unSelectedValue;
    this.changeLanguage(document.documentElement.lang);
  }

  changeLanguage(language: string) {
    document.documentElement.lang = language;
    if (language == 'fr') {
      this.defaultIsSelected = true;
      this.frIcon = frenchIcon.selectedValue;
      this.ukIcon = ukIcon.unSelectedValue;
    } else {
      this.defaultIsSelected = false;
      this.frIcon = frenchIcon.unSelectedValue;
      this.ukIcon = ukIcon.selectedValue;
    }
    this.translate.use(language);
  }
}
