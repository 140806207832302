import { createAction, props } from '@ngrx/store';
import { StudentResponse } from '@sharedModels/student-response';

export const StudentActionsTypes = {
  GET_STUDENT_DATA: 'GET_STUDENT_DATA',
  SET_STUDENT_DATA: 'SET_STUDENT_DATA',
  UPDATE_STUDENT_DATA: 'UPDATE_STUDENT_DATA',
};

const updateStudentData = createAction(StudentActionsTypes.UPDATE_STUDENT_DATA, props<{ updates: Partial<StudentResponse> }>());
const setStudentData = createAction(StudentActionsTypes.SET_STUDENT_DATA, props<StudentResponse>());
const getStudentData = createAction(StudentActionsTypes.GET_STUDENT_DATA);

export const StudentActions = {
  setStudentData,
  updateStudentData,
  getStudentData,
};
