<button
  *ngIf="!stroked"
  mat-button
  [class.mat-flat-button]="stroked"
  [ngClass]="isValid ? 'isValid' : 'notValid'"
  [type]="type"
  [disabled]="disabled"
  extended
  matTooltipHideDelay="300"
  [matTooltipClass]="customTooltip"
  [matTooltipDisabled]="!tooltipContent"
  [matTooltip]="tooltipContent"
  [matTooltipPosition]="tooltipPosition"
  (click)="emitClick()">
  <mat-icon aria-hidden="true" *ngIf="iconName">{{ iconName }}</mat-icon>
  <span>{{ name }} </span>
</button>

<button
  *ngIf="stroked"
  mat-flat-button
  color="primary"
  [class.mat-flat-button]="stroked"
  [ngClass]="isValid ? 'isValid' : 'notValid'"
  [type]="type"
  [disabled]="disabled"
  extended
  matTooltipHideDelay="300"
  [matTooltipClass]="customTooltip"
  [matTooltipDisabled]="!tooltipContent"
  [matTooltip]="tooltipContent"
  [matTooltipPosition]="tooltipPosition"
  (click)="emitClick()">
  <mat-icon aria-hidden="true" *ngIf="iconName">{{ iconName }}</mat-icon>
  <span>{{ name }} </span>
</button>
