import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { UnauthorizedComponent } from '@sharedComponents/unauthorized/unauthorized.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RolesGuard } from './core/guards/roles.guard';

const routes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent, title: 'Unauthorized' },
  {
    path: 'supervisor',
    canActivate: [MsalGuard, AuthGuard, RolesGuard],
    loadChildren: () => import('./views/supervisor/supervisor.module').then(a => a.SupervisorModule),
  },
  {
    path: 'coordinator',
    canActivate: [MsalGuard, AuthGuard, RolesGuard],
    loadChildren: () => import('./views/coordinator/coordinator.module').then(a => a.CoordinatorModule),
  },
  {
    path: 'student',
    canActivate: [MsalGuard, AuthGuard, RolesGuard],
    loadChildren: () => import('./views/student/student.module').then(a => a.StudentModule),
  },
  { path: '**', redirectTo: 'student' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
