<div class="languages-container">
  <div class="fr-container">
    <span [ariaSelected]="defaultIsSelected" aria-label="french translation" class="language-switch" (click)="changeLanguage('fr')">
      <img src="{{ frIcon }}" width="20" height="14"
    /></span>
    FR
  </div>
  <div class="uk-container">
    <span [ariaSelected]="!defaultIsSelected" aria-label="english translation" class="language-switch" (click)="changeLanguage('en')">
      <img src="{{ ukIcon }}" width="20" height="14" />
      EN
    </span>
  </div>
</div>
