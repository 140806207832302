import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from 'src/store/user/user.states';
import { MsalBroadcastService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import GlobalStorageService from '@coreServices/globalStorageService';
import { StepsService, TestModesService } from './shared/api/services';
import { EnumStepStatus, StudentResponse, UserResponse } from './shared/api/models';
import { Specialisation } from '@sharedEnums/specialisation.enum';
import { StudentState } from 'src/store/student/student.states';
import * as studentReducer from 'src/store/student/student.reducer';
import { AutoUnsubscribe } from './shared/decorators/autoUnsubscribe';
import { getUser } from 'src/store/user/user.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@AutoUnsubscribe()
export class AppComponent implements OnInit {
  testMode = false;
  currentUser!: UserResponse;
  currentStudent!: StudentResponse;
  operationResult = '';
  currentEnv!: string;
  public Specialisation = Specialisation;

  private readonly subscription = new Subscription();

  constructor(
    private broadcastService: MsalBroadcastService,
    private globalStorageService: GlobalStorageService,
    private userStore: Store<UserState>,
    private studentStore: Store<StudentState>,
    private stepsService: StepsService,
    private readonly testModesService: TestModesService
  ) {}

  ngOnInit(): void {
    this.currentEnv = environment.mode;
    this.testMode = this.currentEnv == 'dev'; // || this.currentEnv == 'integration'; // Uncomment to get testMode

    this.subscription.add(
      this.userStore.select(getUser).subscribe(user => {
        this.currentUser = user;
      })
    );

    this.subscription.add(
      this.studentStore.select(studentReducer.getStudent).subscribe(result => {
        this.currentStudent = result;
      })
    );
  }

  validateStep() {
    if (this.currentStudent?.currentStepId) {
      this.stepsService.validateStepAndNextAsync({ body: { currentStepId: this.currentStudent.currentStepId } }).subscribe(result => {
        if (result) {
          this.globalStorageService.updateStudentCurrentStepAndDispatchToStore(this.currentStudent, result);
          this.reloadStep();
        }
      });
    } else {
      location.reload();
    }
  }

  /**
   * Deletes current user thesis.
   *  * Only for test purpose
   */
  deleteThesis() {
    const thesisId = this.currentStudent?.thesis?.id;
    if (thesisId)
      this.testModesService.testModeDeleteThesisByIdAsync({ thesisId }).subscribe(result => {
        if (result) this.reloadStep();
      });
  }

  refuseStep() {
    if (this.currentStudent?.currentStepId)
      this.stepsService
        .updateStepAsync({
          stepId: this.currentStudent.currentStepId,
          body: { stepStatus: EnumStepStatus.Refused },
        })
        .subscribe(result => {
          if (result) this.reloadStep();
        });
  }

  /**
   * Switches the current user's specialization.
   *  * Only for test purpose
   *
   * @param specialisation - The new specialization to switch to.
   */
  public switchSpecialization(specialisation: Specialisation) {
    if (this.currentStudent) {
      this.testModesService
        .testModeStudentSwitchSpecialisationAsync({
          studentId: this.currentStudent.id,
          thesisId: this.currentStudent.thesis?.id,
          specialisationCode: specialisation,
        })
        .subscribe(result => {
          if (result) this.reloadStep();
        });
    }
  }

  reloadStep() {
    this.operationResult = 'Opération réussie';
    setTimeout(() => {
      this.operationResult = 'Opération réussie';
    }, 600);
    setTimeout(() => {
      location.reload();
    }, 1200);
  }
}
