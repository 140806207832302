import { createAction, props } from '@ngrx/store';
import { ThesisSupervisorResponse } from '@sharedModels/thesis-supervisor-response';

export const ThesisActionsTypes = {
  SET_THESIS: 'SET_THESIS',
  GET_THESIS: 'GET_THESIS',
};

const setThesisSupervisor = createAction(ThesisActionsTypes.SET_THESIS, props<ThesisSupervisorResponse>());
const getThesisSupervisor = createAction(ThesisActionsTypes.GET_THESIS);

export const ThesisActions = {
  getThesisSupervisor,
  setThesisSupervisor,
};
