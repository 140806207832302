import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  defaultDuration = 2000;

  configuration = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
  } as MatSnackBarConfig;

  constructor(private _snackBar: MatSnackBar, private translateService: TranslateService) {}

  error(message: string) {
    return this._snackBar.open(this.translateService.instant(message), undefined, {
      panelClass: ['snackbar-error'],
      duration: 3000,
      ...this.configuration,
    });
  }

  success(message: string) {
    return this._snackBar.open(this.translateService.instant(message), undefined, {
      panelClass: ['snackbar-success'],
      duration: this.defaultDuration,
      ...this.configuration,
    });
  }

  info(message: string) {
    return this._snackBar.open(this.translateService.instant(message), undefined, {
      panelClass: ['snackbar-info'],
      duration: this.defaultDuration,
      ...this.configuration,
    });
  }
}
