/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommentRequest } from '../models/comment-request';
import { CommentResponse } from '../models/comment-response';
import { UpdateCommentCoordinatorRequest } from '../models/update-comment-coordinator-request';

@Injectable({
  providedIn: 'root',
})
export class CommentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateCommentByIdAsync
   */
  static readonly UpdateCommentByIdAsyncPath = '/Comments';

  /**
   * Update comment by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCommentByIdAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCommentByIdAsync$Plain$Response(params?: {
    commentId?: string;
    body?: UpdateCommentCoordinatorRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CommentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.UpdateCommentByIdAsyncPath, 'put');
    if (params) {
      rb.query('commentId', params.commentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentResponse>;
      })
    );
  }

  /**
   * Update comment by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCommentByIdAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCommentByIdAsync$Plain(params?: {
    commentId?: string;
    body?: UpdateCommentCoordinatorRequest
  },
  context?: HttpContext

): Observable<CommentResponse> {

    return this.updateCommentByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CommentResponse>) => r.body as CommentResponse)
    );
  }

  /**
   * Update comment by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCommentByIdAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCommentByIdAsync$Response(params?: {
    commentId?: string;
    body?: UpdateCommentCoordinatorRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CommentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.UpdateCommentByIdAsyncPath, 'put');
    if (params) {
      rb.query('commentId', params.commentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentResponse>;
      })
    );
  }

  /**
   * Update comment by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCommentByIdAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCommentByIdAsync(params?: {
    commentId?: string;
    body?: UpdateCommentCoordinatorRequest
  },
  context?: HttpContext

): Observable<CommentResponse> {

    return this.updateCommentByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<CommentResponse>) => r.body as CommentResponse)
    );
  }

  /**
   * Path part for operation commentsPost
   */
  static readonly CommentsPostPath = '/Comments';

  /**
   * Get all comments by thesisId, stepNumber and commentType.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commentsPost$Plain$Response(params?: {
    body?: CommentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CommentResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.CommentsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommentResponse>>;
      })
    );
  }

  /**
   * Get all comments by thesisId, stepNumber and commentType.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commentsPost$Plain(params?: {
    body?: CommentRequest
  },
  context?: HttpContext

): Observable<Array<CommentResponse>> {

    return this.commentsPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CommentResponse>>) => r.body as Array<CommentResponse>)
    );
  }

  /**
   * Get all comments by thesisId, stepNumber and commentType.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commentsPost$Response(params?: {
    body?: CommentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CommentResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.CommentsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommentResponse>>;
      })
    );
  }

  /**
   * Get all comments by thesisId, stepNumber and commentType.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commentsPost(params?: {
    body?: CommentRequest
  },
  context?: HttpContext

): Observable<Array<CommentResponse>> {

    return this.commentsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CommentResponse>>) => r.body as Array<CommentResponse>)
    );
  }

}
