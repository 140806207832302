import { createAction, props } from '@ngrx/store';
import { UserResponse } from '@sharedModels/user-response';

export const UserActionsTypes = {
  GET_USER: 'GET_USER',
  SET_USER: 'SET_USER',
  UPDATE_USER: 'UPDATE_USER',
};

const updateUser = createAction(UserActionsTypes.UPDATE_USER, props<{ updates: Partial<UserResponse> }>());
const setUser = createAction(UserActionsTypes.SET_USER, props<UserResponse>());
const getUser = createAction(UserActionsTypes.GET_USER);

export const UserActions = {
  setUser,
  updateUser,
  getUser,
};
