/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateExaminationRequest } from '../models/create-or-update-examination-request';
import { ExaminationResponse } from '../models/examination-response';
import { StepResponse } from '../models/step-response';
import { SupervisorIntermediateValidationPciStepRequest } from '../models/supervisor-intermediate-validation-pci-step-request';
import { SupervisorValidationStepRequest } from '../models/supervisor-validation-step-request';
import { SupervisorValidationStepResponse } from '../models/supervisor-validation-step-response';
import { ThesisItemGridResponse } from '../models/thesis-item-grid-response';
import { ThesisSupervisorResponse } from '../models/thesis-supervisor-response';

@Injectable({
  providedIn: 'root',
})
export class SupervisorsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation documentStepValidationAsync
   */
  static readonly DocumentStepValidationAsyncPath = '/Supervisors';

  /**
   * Supervisor 's validation (Step and document).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentStepValidationAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  documentStepValidationAsync$Plain$Response(params?: {
    body?: SupervisorValidationStepRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupervisorValidationStepResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.DocumentStepValidationAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupervisorValidationStepResponse>;
      })
    );
  }

  /**
   * Supervisor 's validation (Step and document).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentStepValidationAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  documentStepValidationAsync$Plain(params?: {
    body?: SupervisorValidationStepRequest
  },
  context?: HttpContext

): Observable<SupervisorValidationStepResponse> {

    return this.documentStepValidationAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupervisorValidationStepResponse>) => r.body as SupervisorValidationStepResponse)
    );
  }

  /**
   * Supervisor 's validation (Step and document).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentStepValidationAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  documentStepValidationAsync$Response(params?: {
    body?: SupervisorValidationStepRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupervisorValidationStepResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.DocumentStepValidationAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupervisorValidationStepResponse>;
      })
    );
  }

  /**
   * Supervisor 's validation (Step and document).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentStepValidationAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  documentStepValidationAsync(params?: {
    body?: SupervisorValidationStepRequest
  },
  context?: HttpContext

): Observable<SupervisorValidationStepResponse> {

    return this.documentStepValidationAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupervisorValidationStepResponse>) => r.body as SupervisorValidationStepResponse)
    );
  }

  /**
   * Path part for operation documentsPciStepValidationAsync
   */
  static readonly DocumentsPciStepValidationAsyncPath = '/Supervisors';

  /**
   * Supervisor 's validation for Mini-thesis and project documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsPciStepValidationAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  documentsPciStepValidationAsync$Plain$Response(params?: {
    body?: SupervisorIntermediateValidationPciStepRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<StepResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.DocumentsPciStepValidationAsyncPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StepResponse>;
      })
    );
  }

  /**
   * Supervisor 's validation for Mini-thesis and project documents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsPciStepValidationAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  documentsPciStepValidationAsync$Plain(params?: {
    body?: SupervisorIntermediateValidationPciStepRequest
  },
  context?: HttpContext

): Observable<StepResponse> {

    return this.documentsPciStepValidationAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<StepResponse>) => r.body as StepResponse)
    );
  }

  /**
   * Supervisor 's validation for Mini-thesis and project documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsPciStepValidationAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  documentsPciStepValidationAsync$Response(params?: {
    body?: SupervisorIntermediateValidationPciStepRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<StepResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.DocumentsPciStepValidationAsyncPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StepResponse>;
      })
    );
  }

  /**
   * Supervisor 's validation for Mini-thesis and project documents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentsPciStepValidationAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  documentsPciStepValidationAsync(params?: {
    body?: SupervisorIntermediateValidationPciStepRequest
  },
  context?: HttpContext

): Observable<StepResponse> {

    return this.documentsPciStepValidationAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<StepResponse>) => r.body as StepResponse)
    );
  }

  /**
   * Path part for operation deleteStepCommentAsync
   */
  static readonly DeleteStepCommentAsyncPath = '/Supervisors/{commentId}';

  /**
   * Delete a step comment by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteStepCommentAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStepCommentAsync$Plain$Response(params: {
    commentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.DeleteStepCommentAsyncPath, 'delete');
    if (params) {
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a step comment by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteStepCommentAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStepCommentAsync$Plain(params: {
    commentId: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.deleteStepCommentAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete a step comment by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteStepCommentAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStepCommentAsync$Response(params: {
    commentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.DeleteStepCommentAsyncPath, 'delete');
    if (params) {
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a step comment by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteStepCommentAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStepCommentAsync(params: {
    commentId: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.deleteStepCommentAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getThesisSupervisorByIdAsync
   */
  static readonly GetThesisSupervisorByIdAsyncPath = '/Supervisors/Thesis/{thesisId}';

  /**
   * Get thesis by id for supervisor view.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getThesisSupervisorByIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThesisSupervisorByIdAsync$Plain$Response(params: {
    thesisId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisSupervisorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.GetThesisSupervisorByIdAsyncPath, 'get');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisSupervisorResponse>;
      })
    );
  }

  /**
   * Get thesis by id for supervisor view.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getThesisSupervisorByIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThesisSupervisorByIdAsync$Plain(params: {
    thesisId: string;
  },
  context?: HttpContext

): Observable<ThesisSupervisorResponse> {

    return this.getThesisSupervisorByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisSupervisorResponse>) => r.body as ThesisSupervisorResponse)
    );
  }

  /**
   * Get thesis by id for supervisor view.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getThesisSupervisorByIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThesisSupervisorByIdAsync$Response(params: {
    thesisId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ThesisSupervisorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.GetThesisSupervisorByIdAsyncPath, 'get');
    if (params) {
      rb.path('thesisId', params.thesisId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThesisSupervisorResponse>;
      })
    );
  }

  /**
   * Get thesis by id for supervisor view.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getThesisSupervisorByIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThesisSupervisorByIdAsync(params: {
    thesisId: string;
  },
  context?: HttpContext

): Observable<ThesisSupervisorResponse> {

    return this.getThesisSupervisorByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ThesisSupervisorResponse>) => r.body as ThesisSupervisorResponse)
    );
  }

  /**
   * Path part for operation getAllThesisBySupervisorIdAsync
   */
  static readonly GetAllThesisBySupervisorIdAsyncPath = '/Supervisors/Thesis/Grid/{supervisorId}';

  /**
   * Get all thesis and related data by supervisorId (SectorDirector, Supervisor, co-supervisorId)
   * used to generate grid supervisors views.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllThesisBySupervisorIdAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllThesisBySupervisorIdAsync$Plain$Response(params: {
    supervisorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ThesisItemGridResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.GetAllThesisBySupervisorIdAsyncPath, 'get');
    if (params) {
      rb.path('supervisorId', params.supervisorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ThesisItemGridResponse>>;
      })
    );
  }

  /**
   * Get all thesis and related data by supervisorId (SectorDirector, Supervisor, co-supervisorId)
   * used to generate grid supervisors views.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllThesisBySupervisorIdAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllThesisBySupervisorIdAsync$Plain(params: {
    supervisorId: string;
  },
  context?: HttpContext

): Observable<Array<ThesisItemGridResponse>> {

    return this.getAllThesisBySupervisorIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ThesisItemGridResponse>>) => r.body as Array<ThesisItemGridResponse>)
    );
  }

  /**
   * Get all thesis and related data by supervisorId (SectorDirector, Supervisor, co-supervisorId)
   * used to generate grid supervisors views.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllThesisBySupervisorIdAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllThesisBySupervisorIdAsync$Response(params: {
    supervisorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ThesisItemGridResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.GetAllThesisBySupervisorIdAsyncPath, 'get');
    if (params) {
      rb.path('supervisorId', params.supervisorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ThesisItemGridResponse>>;
      })
    );
  }

  /**
   * Get all thesis and related data by supervisorId (SectorDirector, Supervisor, co-supervisorId)
   * used to generate grid supervisors views.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllThesisBySupervisorIdAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllThesisBySupervisorIdAsync(params: {
    supervisorId: string;
  },
  context?: HttpContext

): Observable<Array<ThesisItemGridResponse>> {

    return this.getAllThesisBySupervisorIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ThesisItemGridResponse>>) => r.body as Array<ThesisItemGridResponse>)
    );
  }

  /**
   * Path part for operation updateExaminationSupervisorCoordinatorByIdAsync
   */
  static readonly UpdateExaminationSupervisorCoordinatorByIdAsyncPath = '/Supervisors/Examination/{examinationId}';

  /**
   * Update examination Supervisor/Coordinator and create document when exmination is validated.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExaminationSupervisorCoordinatorByIdAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExaminationSupervisorCoordinatorByIdAsync$Plain$Response(params: {
    examinationId: string;
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ExaminationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.UpdateExaminationSupervisorCoordinatorByIdAsyncPath, 'put');
    if (params) {
      rb.path('examinationId', params.examinationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationResponse>;
      })
    );
  }

  /**
   * Update examination Supervisor/Coordinator and create document when exmination is validated.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExaminationSupervisorCoordinatorByIdAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExaminationSupervisorCoordinatorByIdAsync$Plain(params: {
    examinationId: string;
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<ExaminationResponse> {

    return this.updateExaminationSupervisorCoordinatorByIdAsync$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ExaminationResponse>) => r.body as ExaminationResponse)
    );
  }

  /**
   * Update examination Supervisor/Coordinator and create document when exmination is validated.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExaminationSupervisorCoordinatorByIdAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExaminationSupervisorCoordinatorByIdAsync$Response(params: {
    examinationId: string;
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ExaminationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupervisorsService.UpdateExaminationSupervisorCoordinatorByIdAsyncPath, 'put');
    if (params) {
      rb.path('examinationId', params.examinationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationResponse>;
      })
    );
  }

  /**
   * Update examination Supervisor/Coordinator and create document when exmination is validated.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExaminationSupervisorCoordinatorByIdAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExaminationSupervisorCoordinatorByIdAsync(params: {
    examinationId: string;
    body?: CreateOrUpdateExaminationRequest
  },
  context?: HttpContext

): Observable<ExaminationResponse> {

    return this.updateExaminationSupervisorCoordinatorByIdAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<ExaminationResponse>) => r.body as ExaminationResponse)
    );
  }

}
