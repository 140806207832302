import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from '@coreServices/monitoringService/monitoring.service';
import { NotificationService } from '@sharedServices/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private monitoringService: MonitoringService, private notificationService: NotificationService) {
    super();
  }

  override handleError(error: Error) {
    this.notificationService.error('ERROR.SAVE');
    this.monitoringService.logException(error);
    super.handleError(error);
  }
}
