import { Action, ActionReducer, ActionReducerMap } from '@ngrx/store';
import { AppState } from './app.states';
import { userReducer } from '../user/user.reducer';
import { UserState } from '../user/user.states';
import { thesisSupervisorReducer } from '../thesis/thesis.reducer';
import { studentReducer } from '../student/student.reducer';
import { ThesisSupervisorState } from '../thesis/thesis.states';
import { StudentState } from '../student/student.states';
import { supervisorReducer } from '../supervisor/supervisor.reducer';
import { SupervisorState } from '../supervisor/supervisor.states';
import { ThesisTimelineState } from '../thesis-timeline/thesis-timeline.states';
import { thesisTimelineReducer } from '../thesis-timeline/thesis-timeline.reducer';
import { coordinatorReducer } from '../coordinator/coordinator.reducer';
import { CoordinatorState } from '../coordinator/coordinator.states';

export const appReducer: ActionReducerMap<AppState> = {
  userState: userReducer as ActionReducer<UserState, Action>,
  thesisSupervisorState: thesisSupervisorReducer as ActionReducer<ThesisSupervisorState, Action>,
  studentState: studentReducer as ActionReducer<StudentState, Action>,
  thesisTimelineState: thesisTimelineReducer as ActionReducer<ThesisTimelineState, Action>,
  supervisorState: supervisorReducer as ActionReducer<SupervisorState, Action>,
  coordinatorState: coordinatorReducer as ActionReducer<CoordinatorState, Action>,
};
