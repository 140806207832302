import { Action, ActionReducer, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SupervisorState } from './supervisor.states';
import { SupervisorActions } from './supervisor.actions';
import { SupervisorResponse } from '@sharedModels/supervisor-response';
import { Roles } from '@sharedEnums/roles.enum';

const initialState: SupervisorState = {
  currentSupervisor: null as SupervisorResponse | null,
  thesisRole: {} as Roles,
};

const _supervisorReducer: ActionReducer<SupervisorState, Action> = createReducer(
  // Supply the initial state
  initialState,
  on(SupervisorActions.setSupervisor, (state: SupervisorState, data: SupervisorResponse) => ({
    ...state,
    currentSupervisor: { ...data },
  })),
  on(SupervisorActions.getSupervisor, (state: SupervisorState) => ({
    ...state,
    currentSupervisor: {} as SupervisorResponse,
  })),
  on(SupervisorActions.setThesisRole, (state: SupervisorState, { thesisRole }) => ({
    ...state,
    thesisRole: thesisRole,
  }))
);

export function supervisorReducer(state: SupervisorState, action: Action) {
  return _supervisorReducer(state, action);
}

// Selectors
export const getSupervisorState = createFeatureSelector<SupervisorState>('supervisorState');
export const getSupervisor = createSelector(getSupervisorState, (state: SupervisorState) => state);
