import { Action, ActionReducer, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { UserState } from './user.states';
import { UserActions } from './user.actions';
import { UserResponse } from '@sharedModels/user-response';
import { Roles } from '@sharedEnums/roles.enum';

const initialState: UserState = {
  currentUser: {} as UserResponse,
};

const _userReducer: ActionReducer<UserState, Action> = createReducer(
  // Supply the initial state
  initialState,
  on(UserActions.setUser, (state: UserState, user: UserResponse) => ({
    ...state,
    currentUser: { ...user },
  })),
  on(UserActions.getUser, (state: UserState) => ({
    ...state,
    currentUser: {} as UserResponse,
    thesisRole: {} as Roles,
  })),
  on(UserActions.updateUser, (state: UserState, { updates }) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      ...updates,
      userData: {
        ...state.currentUser,
        ...updates,
      },
    },
  }))
);

export function userReducer(state: UserState, action: Action) {
  return _userReducer(state, action);
}

// Selectors
export const getUserState = createFeatureSelector<UserState>('userState');
export const getUser = createSelector(getUserState, (state: UserState) => state.currentUser);
