import { createAction, props } from '@ngrx/store';
import { CoordinatorResponse } from '@sharedModels/coordinator-response';

export const CoordinatorActionsTypes = {
  GET_COORDINATOR: 'SET_COORDINATOR',
  SET_COORDINATOR: 'SET_COORDINATOR',
};

const setCoordinator = createAction(CoordinatorActionsTypes.SET_COORDINATOR, props<CoordinatorResponse>());
const getCoordinator = createAction(CoordinatorActionsTypes.GET_COORDINATOR);

export const CoordinatorActions = {
  setCoordinator,
  getCoordinator,
};
